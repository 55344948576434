import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_CLIENT_LIST = "populateClientList";
export const POPULATE_CLIENTS_STATS = "populateClientStats";
export const CREATE_CLIENT = "createClient";
export const UPDATE_CLIENT = "updateClient";
export const UPDATE_CLIENT_INFO = "updateClientInfo";
export const PRELOAD_CLIENT = "preloadClient";
export const CLEAR_CLIENT_LIST = "clearClientList";
export const CLEAR_CLIENT_STATS = "clearClientStats";
export const CLEAR_CLIENT_INFO = "clearClientInfo";
export const CLEAR_CLIENT_ERRORS = "clearClientErrors";

// mutation types
export const SET_CLIENT_INFO = "setClientInfo";
export const RESET_CLIENT_INFO = "resetClientInfo";
export const RESET_CLIENT_STATS = "resetClientStats";
export const RESET_CLIENT_LIST = "resetClientList";
export const SET_CLIENT_LIST = "setClientList";
export const SET_CLIENT_STATS = "setClientStats";
export const SET_CLIENT_PRELOAD = "setClientPreload";
export const CLEAR_CLIENT_PRELOAD = "clearClientPreload";
export const SET_ERRORS = "setErrors";
export const CLEAR_ERRORS = "clearErrors";

const state = {
  errors: {},
  client_list: [],
  client_stats: {
    Active: null,
    Draft: null
  },
  client_info: {
    name: null,
    key: null,
    custom_domain: null
  },
  client_preload: {
    id: null
  }
};

const getters = {
  clientErrors(state) {
    return state.errors;
  },
  clientList(state) {
    return state.client_list;
  },
  clientStats(state) {
    return state.client_stats;
  },
  clientInfo(state) {
    return state.client_info;
  },
  clientPreload(state) {
    return state.client_preload;
  }
};

const actions = {
  [POPULATE_CLIENT_LIST](context, payload) {
    return ApiService.get("api/v1/clients", payload).then(res => {
      const clients = res.data.data._embedded.clients;
      context.commit(SET_CLIENT_LIST, clients);
    });
  },
  [POPULATE_CLIENTS_STATS](context) {
    return ApiService.get("api/v1/client_stats").then(res => {
      const stats = res.data;
      context.commit(SET_CLIENT_STATS, stats);
    });
  },
  [CREATE_CLIENT](context, payload) {
    return ApiService.post("api/v1/clients", payload)
      .then(res => {
        context.commit(SET_CLIENT_INFO, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_CLIENT](context, payload) {
    return ApiService.put("api/v1/clients/" + payload.clientId, payload.data)
      .then(res => {
        context.commit(SET_CLIENT_INFO, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_CLIENT_INFO](context, clientId) {
    return ApiService.get("api/v1/clients/" + clientId)
      .then(res => {
        const client = res.data.data;
        context.commit(SET_CLIENT_INFO, client);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [PRELOAD_CLIENT](context, clientId) {
    context.commit(SET_CLIENT_PRELOAD, clientId);
  },
  [CLEAR_CLIENT_LIST](context) {
    context.commit(RESET_CLIENT_LIST);
  },
  [CLEAR_CLIENT_STATS](context) {
    context.commit(RESET_CLIENT_STATS);
  },
  [CLEAR_CLIENT_INFO](context) {
    context.commit(RESET_CLIENT_INFO);
  },
  [CLEAR_CLIENT_ERRORS](context) {
    context.commit(CLEAR_ERRORS);
  }
};

const mutations = {
  [SET_CLIENT_INFO](state, client_info) {
    state.client_info = client_info;
  },
  [RESET_CLIENT_INFO](state) {
    state.client_info = { name: null, key: null, custom_domain: null };
  },
  [RESET_CLIENT_STATS](state) {
    state.client_stats = {
      Active: null,
      Draft: null
    };
  },
  [RESET_CLIENT_LIST](state) {
    state.client_list = null;
  },
  [SET_CLIENT_LIST](state, client_list) {
    state.client_list = client_list;
  },
  [SET_CLIENT_STATS](state, stats) {
    state.client_stats = stats;
  },
  [SET_CLIENT_PRELOAD](state, clientId) {
    state.client_preload.id = clientId;
  },
  [CLEAR_CLIENT_PRELOAD](state) {
    state.client_preload.id = null;
  },
  [SET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [CLEAR_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
