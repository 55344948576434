<template>
  <span>
    <span v-if="displayTooltip" id="date-display" v-b-tooltip.hover :title="timezone"> {{ formattedDate }} </span>
    <span v-else id="date-display-relative"> {{ formattedDate }} </span>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "GenericDatetimeDisplay",
  
  props: {
    long: { type: Boolean, required: false },
    date: { type: String, required: true },
    lastUpdated: { type: Boolean, required: true },
    relative: { type: Boolean, required: true },
    createdAt: { type: Boolean, required: false }
  },

  methods: {
    convertFromUTCTimezone(date, format) {
      if (this.currentUser.system_timezone_setting_id == 1) {
        return moment.utc(date, "YYYY-MM-DD HH:mm:ss Z").local().format(format);
      } else if (this.currentUser.system_timezone_setting_id == 2) {
        if (this.projectInfo.systemTimezone != null) {
          return moment.utc(date, "YYYY-MM-DD HH:mm:ss Z").utcOffset(this.projectInfo.systemTimezone.offset).format(format);
        } else {
          return moment.utc(date, "YYYY-MM-DD HH:mm:ss Z").local().format(format);
        };
      } else {
        return moment.utc(date, "YYYY-MM-DD HH:mm:ss Z").utcOffset(this.currentUser.systemTimezone.offset).format(format);
      }
    }
  },

  computed: {
      ...mapGetters([
      "currentUser",
      "projectInfo",
      "systemTimezones"
    ]),
    formattedDate() {
      if (this.relative == true) {
        return moment.utc(this.dateUTC).fromNow();
      } else if (this.lastUpdated == true) {
        var now = moment(new Date());
        var utc = this.convertFromUTCTimezone(this.dateUTC, "YYYY-MM-DD HH:mm:ss");
        var hours = now.diff(utc, 'hours', true);
        
        if (hours > 8) {
          return this.convertFromUTCTimezone(this.dateUTC, "Do MMMM YY HH:mm")
        } else {
          return moment.utc(this.dateUTC, "YYYY-MM-DD HH:mm:ss Z").fromNow();
        }
      } else {
        if (this.long != undefined) {
          if (this.long == true) {
            return this.convertFromUTCTimezone(this.dateUTC, "Do MMMM YY HH:mm");
          } else {
            return this.convertFromUTCTimezone(this.dateUTC, "DD/MM/YYYY HH:mm");
          }
        } else {
          return this.convertFromUTCTimezone(this.dateUTC, "DD/MM/YYYY HH:mm");
        }
      }      
    },
    timezone() {
      var tooltip = 'UTC';
      tooltip = tooltip + this.convertFromUTCTimezone(this.dateUTC, "ZZ");

      return tooltip;
    },
    displayTooltip() {
      var now = moment(new Date());
      var utc = this.convertFromUTCTimezone(this.dateUTC, "YYYY-MM-DD HH:mm:ss");
      var hours = now.diff(utc, 'hours', true);

      if (this.relative == false && (this.lastUpdated == false || (this.lastUpdated == true && hours > 8))) {
        return true;
      } else {
        return false;
      };
    },
    dateUTC: function() {
      if (this.lastUpdated == true || this.createdAt == true) {
        return new Date(this.date);
      } else {
        return this.date + ' +0000';
      }
    }
  }
};
</script>
