<template>
  <span @click="openProfileModal(user.id)" :style="cursorStyle">
    <span v-if="anonymise" class="symbol" :class="computedClassList">
      <span
        class="symbol-label font-size-h5 font-weight-bold"
        style="text-decoration: none"
      >
        {{ firstInitial }}
      </span>
    </span>
    <span v-else class="symbol" :class="computedClassList">
      <img
        alt="Pic"
        :class="{ 'rounded-20' : shape == 'square', 'rounded-circle' : shape == 'circle' }"
        :src="user.signedAvatar"
        style="object-fit: cover !important"
      />
    </span>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserThumbnail",

  props: {
    user: { type: Object, required: true },
    size: { type: String, required: false },
    shape: { type: String, required: false, default: "square" },
    canOpenProfile: { type: Boolean, required: false, default: false }
  },

  methods: {
    openProfileModal: function(val) {
      if (this.canOpenProfile) {
        this.$emit('open_profile_modal', val);
      };
    }
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    firstInitial: function() {
      if (this.user.system_register_type_id != 2) {
        return this.user.display_name[0].toUpperCase();
      } else {
        return "U"
      }
    },
    buttonBgClass: function() {
      let asciiCode = this.firstInitial.charCodeAt(0);
      let btnClass = null;

      if (asciiCode <= 69) {
        btnClass = "symbol-light-danger";
      } else if (asciiCode <= 73) {
        btnClass = "symbol-light-success";
      } else if (asciiCode <= 78) {
        btnClass = "symbol-light-primary";
      } else if (asciiCode <= 82 ) {
        btnClass = "symbol-light-info";
      } else if (asciiCode <= 86) {
        btnClass = "symbol-light-warning";
      } else if (asciiCode <= 90) {
        btnClass = "symbol-light-secondary";
      }

      return btnClass;
    },
    computedSizeClass: function() {
      if (this.size != undefined) {
        return 'symbol-lg-' + this.size + " symbol-" + this.size;
      } else {
        return "symbol-lg-35 symbol-35";
      }
    },
    computedClassList: function() {
      return this.buttonBgClass + " " + this.computedSizeClass;
    },
    anonymise: function() {
      if (this.user.avatar == null || this.user.signedAvatar == null) {
        return true;
      } else if (this.currentUser.projectProfile != undefined) {
        if (this.currentUser.projectProfile.system_project_role_id == 2) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    cursorStyle: function() {
      if (this.canOpenProfile) {
        return "cursor: pointer !important";
      } else {
        return "cursor: inherit !important";
      }
    }
  }
};
</script>
<style scoped>
.rounded-circle{
  border: 2px solid white
}
</style>