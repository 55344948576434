<template>
  <a
    href="javascript:void(0)"
    @click="toggleSentiment"
    class="btn btn-icon-danger btn-sm btn-text-dark-50 bg-hover-light-danger btn-hover-text-danger rounded font-weight-bolder font-size-xs p-2"
    v-b-tooltip.hover.bottom
    :title="tooltip"
    :disabled="submitting"
    :style="legacy ? 'cursor: not-allowed' : null"
  >
    <span class="svg-icon svg-icon-md svg-icon-danger pr-1">
      <i
        class="fa-heart text-danger fa-lg"
        :class="{ 'far' : mySentiment == null, 'fas' : mySentiment != null }"
      >
      </i>
    </span>
    {{ totalSentiments }}
  </a>
</template>

<script>
import { mapGetters } from "vuex";

import {
  CREATE_SENTIMENT_ENGAGEMENTS,
  DELETE_SENTIMENT_ENGAGEMENTS
} from "@/core/services/store/activity/activity_sentiment_engagements.module";

export default {
  name: "EngagementSentiment",

  props: {
    sentiments: { type: Array, required: true },
    activityId: { type: Number, required: true },
    sentimentableType: { type: String, required: true },
    sentimentableId: { type: Number, required: true },
    legacy: { type: Boolean, required: false, default: false }
  },

  data() {
    return {
      mySentiment: null,
      submitting: false
    }
  },

  mounted() {
    let currentUserId = this.currentUser.id;

    let mySentiment = this.sentiments.filter(function( sentiment ) {
      return sentiment.user_id == currentUserId;
    });

    if (mySentiment.length == 1) {
      this.mySentiment = mySentiment[0];
    }
  },

  methods: {
    toggleSentiment: function() {

      if (this.submitting || this.legacy) {
        return;
      }

      this.submitting = true;

      let action = null;
      let payload = {};

      if (this.iHaveEngaged) {
        action = DELETE_SENTIMENT_ENGAGEMENTS;
        payload = {
          activityId: this.activityId,
          engagementId: this.mySentiment.id
        };
      } else {
        action = CREATE_SENTIMENT_ENGAGEMENTS;
        payload = {
          activityId: this.activityId,
          data: {
            sentiment: 1,
            sentimentable_type: this.sentimentableType,
            sentimentable_id: this.sentimentableId
          }
        };
      }

      this.$store
        .dispatch(action, payload)
        .then(res => {
          if (this.iHaveEngaged) {
            this.mySentiment = null;
          } else {
            this.mySentiment = res;
          }
        })
        .finally(() => {
          this.submitting = false;
        })
    }
  },

  computed: {
    ...mapGetters(["currentUser"]),

    otherSentiments: function() {
      let currentUserId = this.currentUser.id;

      return this.sentiments.filter(function( sentiment ) {
        return sentiment.user_id !== currentUserId;
      });
    },

    iHaveEngaged: function() {
      return (this.mySentiment == null) ? false : true;
    },

    totalSentiments: function() {
      if (this.iHaveEngaged) {
        return this.otherSentiments.length + 1;
      } else {
        return this.otherSentiments.length;
      }
    },

    tooltip: function() {
      let tooltip = null;

      if (this.mySentiment != null) {
        return `${this.$t("REVIEW.STREAM.SENTIMENT_SUMMARY_ME")}`;
      } else if (this.totalSentiments == 1) {
        return this.totalSentiments + `${this.$t("REVIEW.STREAM.SENTIMENT_SUMMARY_SINGULAR")}`;
      } else if (this.totalSentiments > 1 || this.totalSentiments == 0) {
        return this.totalSentiments + `${this.$t("REVIEW.STREAM.SENTIMENT_SUMMARY_PLURAL")}`;
      };

      return tooltip;
    }
  }
};
</script>
