import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Vue from "vue";

// action types
export const LOGIN = "login";
export const LOGOUT = "logout";
export const RESET = "reset";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const CLEAR = "clear";
export const RESEND_VERIFY = "resendVerify";
export const VERIFY_EMAIL = "verify";
export const REQUEST_RESET = "requestReset";
export const RESET_PASSWORD = "resetPassword";
export const VERIFY_AUTH = "verifyAuth";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const PURGE_ERRORS = "purgeErrors";
export const VERIFY = "verify";

const state = {
  errors: {},
  user: {},
  isAuthenticated: false
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  authenticationErrors(state) {
    return state.errors;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.get("/sanctum/csrf-cookie").then(function() {
        ApiService.post("auth/login", credentials)
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            resolve(data);
          })
          .catch(error => {
            if (error.response) {
              context.commit(SET_ERROR, error.response.data);
            } else {
              Vue.$log.error(error.response);
            }
            reject();
          });
      });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/logout")
        .then(({ data }) => {
          context.commit(PURGE_AUTH);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [RESET](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.get("/sanctum/csrf-cookie").then(function() {
        ApiService.post("/auth/register/" + credentials.projectId + "/" + credentials.route, credentials)
          .then(function() {
            context
              .dispatch(LOGIN, {
                email: credentials.email,
                password: credentials.password
              })
              .then(() => {
                resolve();
              });
          })
          .catch(error => {
            context.commit(SET_ERROR, error.response.data);
            reject();
          });
      });
    });
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
  [CLEAR](context) {
    context.commit(PURGE_ERRORS);
  },
  [RESEND_VERIFY](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/verification-notification")
        .then(function() {
          resolve();
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_EMAIL](context, verification) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/verify-email", verification)
        .then(data => {
          context.commit(VERIFY, data.data);
          resolve();
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [REQUEST_RESET](context, email) {
    return new Promise((resolve, reject) => {
      ApiService.get("/sanctum/csrf-cookie").then(function() {
        ApiService.post("/auth/request-reset", {
          email: email.email,
          origin: window.location.origin
        })
          .then(() => {
            resolve();
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
            reject(response);
          });
      });
    });
  },
  [RESET_PASSWORD](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/handle-reset", data)
        .then(() => {
          resolve();
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data);
          reject();
        });
    });
  },
  [VERIFY_AUTH](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/v1/auth-user", {
          params: {
            scope: 'input',
            domain: window.location.host.split('.')[0]
          }
      })
        .then((res) => {
          context.commit(SET_AUTH, res.data.data);
          resolve(res);
        })
        .catch(({ response }) => {
          context.commit(PURGE_AUTH);
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [PURGE_ERRORS](state) {
    state.errors = {};
  },
  [VERIFY](state, verified_at) {
    state.user.email_verified_at = verified_at;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
