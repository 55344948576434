import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_PROJECT_LIST = "populateProjectList";
export const POPULATE_CLIENT_PROJECT_LIST = "populateProjectList";
export const POPULATE_PROJECTS_STATS = "populateProjectStats";
export const POPULATE_PROJECT_HEALTH = "populateProjectHealth";
export const POPULATE_PROJECT_SEGMENT_STATS = "populateProjectSegmentStats";
export const CLEAR_PROJECT_LIST = "clearProjectList";
//export const CREATE_PROJECT = "createProject";
//export const UPDATE_PROJECT = "updateProject";
export const CLEAR_PROJECT_STATS = "clearProjectStats";
export const UPDATE_PROJECT_INFO = "updateProjectInfo";
export const CLEAR_PROJECT_INFO = "clearProjectInfo";
export const CLEAR_PROJECT_HEALTH = "clearProjectHealth";
export const CLEAR_PROJECT_SEGMENT_STATS = "clearProjectSegmentStats";
export const CLEAR_PROJECT_ERRORS = "clearProjectErrors";

// mutation types
export const SET_PROJECT_LIST = "setProjectList";
export const RESET_PROJECT_LIST = "resetProjectList";
export const SET_PROJECT_INFO = "setProjectInfo";
export const RESET_PROJECT_INFO = "resetProjectInfo";
export const SET_PROJECT_ERRORS = "setProjectErrors";
export const RESET_PROJECT_ERRORS = "resetProjectErrors";
export const SET_PROJECT_STATS = "setProjectStats";
export const RESET_PROJECT_STATS = "resetProjectStats";
export const SET_PROJECT_HEALTH = "setProjectHealth";
export const RESET_PROJECT_HEALTH = "resetProjectHealth";
export const SET_PROJECT_SEGMENT_STATS = "setProjectSegmentStats";
export const RESET_PROJECT_SEGMENT_STATS = "resetProjectSegmentStats";

const state = {
  errors: {},
  project_list: [],
  project_stats: {
    Draft: null,
    Recruitment: null,
    Active: null,
    Review: null,
    Closed: null
  },
  project_segment_stats: [],
  project_info: [],
  project_health: {}
};

const getters = {
  projectErrors(state) {
    return state.errors;
  },
  projectList(state) {
    return state.project_list;
  },
  projectStats(state) {
    return state.project_stats;
  },
  projectInfo(state) {
    return state.project_info;
  },
  projectHealth(state) {
    return state.project_health;
  },
  projectSegmentStats(state) {
    return state.project_segment_stats;
  }
};

const actions = {
  [POPULATE_PROJECT_LIST](context) {
    return ApiService.get("api/v1/projects", {
      params: {
        paginate: false
      }
    }).then(res => {
      const projects = res.data.data._embedded.projects;
      context.commit(SET_PROJECT_LIST, projects);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_PROJECT_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [POPULATE_CLIENT_PROJECT_LIST](context, payload) {
    return ApiService.get("api/v1/clients/" + payload.clientId + "/projects", payload.data)
      .then(res => {
        const clientProjects = res.data.data._embedded.projects;
        context.commit(SET_PROJECT_LIST, clientProjects);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_PROJECTS_STATS](context) {
    return ApiService.get("api/v1/project_stats").then(res => {
      const stats = res.data;
      context.commit(SET_PROJECT_STATS, stats);
    });
  },
  [POPULATE_PROJECT_HEALTH](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/project_health", {
      params: payload.params
    }).then(res => {
      const projectHealth = res.data.data;
      context.commit(SET_PROJECT_HEALTH, projectHealth);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_PROJECT_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [POPULATE_PROJECT_SEGMENT_STATS](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/project_segment_stats")
    .then(res => {
      const projectSegmentStats = res.data;
      context.commit(SET_PROJECT_SEGMENT_STATS, projectSegmentStats);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_PROJECT_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [CLEAR_PROJECT_LIST](context) {
    context.commit(RESET_PROJECT_LIST);
  },
  [CLEAR_PROJECT_STATS](context) {
    context.commit(RESET_PROJECT_STATS);
  },
  [UPDATE_PROJECT_INFO](context, payload) {
    return ApiService.get("api/v1/clients/" + payload.clientIdentifier + "/projects/" + payload.projectIdentifier, {
      params: payload.params
    })
      .then((res) => {
        const project = res.data.data;
        context.commit(SET_PROJECT_INFO, project);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_PROJECT_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_INFO](context) {
    context.commit(RESET_PROJECT_INFO);
  },
  [CLEAR_PROJECT_HEALTH](context) {
    context.commit(RESET_PROJECT_HEALTH);
  },
  [CLEAR_PROJECT_SEGMENT_STATS](context) {
    context.commit(RESET_PROJECT_SEGMENT_STATS);
  },
  [CLEAR_PROJECT_ERRORS](context) {
    context.commit(RESET_PROJECT_ERRORS);
  }
}

const mutations = {
  [SET_PROJECT_LIST](state, projects) {
    state.project_list = projects;
  },
  [RESET_PROJECT_LIST](state) {
    state.project_list = [];
  },
  [SET_PROJECT_INFO](state, project) {
    state.project_info = project;
  },
  [RESET_PROJECT_INFO](state) {
    state.project_info = [];
  },
  [SET_PROJECT_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_PROJECT_ERRORS](state) {
    state.errors = {};
  },
  [SET_PROJECT_STATS](state, stats) {
    state.project_stats = stats;
  },
  [RESET_PROJECT_STATS](state) {
    state.project_stats = {
      Draft: null,
      Recruitment: null,
      Active: null,
      Review: null,
      Closed: null
    }
  },
  [SET_PROJECT_HEALTH](state, projectHealth) {
    state.project_health = projectHealth;
  },
  [RESET_PROJECT_HEALTH](state) {
    state.project_health = {};
  },
  [SET_PROJECT_SEGMENT_STATS](state, projectSegmentStats) {
    state.project_segment_stats = projectSegmentStats;
  },
  [RESET_PROJECT_SEGMENT_STATS](state) {
    state.project_segment_stats = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};