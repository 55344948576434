// action types

// mutation types

const state = {
  form: {
    name: "",
    email: "",
    display: "",
    password: null,
    password_confirmation: null,
    basic_profile_answers: {
      avatar: null,
      gender: null,
      dob: "",
      address: null,
      city: null,
      state: null,
      country: null
    },
    extended_profile_answers: []
  }
};

const getters = {
  registerWorkflowForm(state) {
    return state.form;
  }
};

const actions = {};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};