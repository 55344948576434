import ApiService from "@/core/services/api.service";

// action types
export const POPULATE_VERSION = "populateVersion";

// mutation types
export const SET_VERSION = "setVersion";

const state = {
  version: null
};

const getters = {
  version(state) {
    return state.version;
  }
};

const actions = {
  [POPULATE_VERSION](context) {
    return ApiService.get("api/v1/version").then(res => {
      context.commit(SET_VERSION, res.data.data);
    }).catch(error => {
      throw error;
    });
  }
};

const mutations = {
  [SET_VERSION](state, version) {
    state.version = version;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
