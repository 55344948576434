// action types
export const ADD_MENU_TYPE = "addMenuType";
export const REMOVE_MENU_TYPE = "removeMenuType";

// mutation types
export const SET_MENU_TYPE = "setMenuType";
export const RESET_MENU_TYPE = "resetMenuType";

const state = {
  menu_type: null
};

const getters = {
  menuType(state) {
    return state.menu_type;
  }
};

const actions = {
  [ADD_MENU_TYPE](context, menu_type) {
    context.commit(SET_MENU_TYPE, menu_type);
  },
  [REMOVE_MENU_TYPE](context) {
    context.commit(RESET_MENU_TYPE);
  }
};

const mutations = {
  [SET_MENU_TYPE](state, menu_type) {
    state.menu_type = menu_type;
  },
  [RESET_MENU_TYPE](state) {
    state.menu_type = null;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
