import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ACTIVITY_DIARY_RESPONSES_LIST =
  "populateActivityDiaryRepsonsesList";
export const POPULATE_ACTIVITY_DIARY_RESPONSES_INFO =
  "populateActivityDiaryRepsonsesInfo";
export const POPULATE_ACTIVITY_ADDITIONAL_DIARY_RESPONSES_INFO =
  "populateActivityAdditionalDiaryRepsonsesInfo";
export const CREATE_DIARY_RESPONSES = "createDiaryRepsonses";
export const UPDATE_DIARY_RESPONSES = "updateDiaryRepsonses";
export const DELETE_DIARY_RESPONSES = "deleteDiaryRepsonses";
export const CLEAR_DIARY_RESPONSES_LIST = "clearDiaryRepsonsesList";
export const CLEAR_DIARY_RESPONSES_INFO = "clearDiaryRepsonsesInfo";
export const CLEAR_DIARY_RESPONSES_ERRORS = "clearDiaryRepsonsesErrors";

// mutation types
export const SET_ACTIVITY_DIARY_RESPONSES_LIST =
  "setActivityDiaryRepsonsesList";
export const RESET_ACTIVITY_DIARY_RESPONSES_LIST =
  "resetActivityDiaryRepsonsesList";
export const SET_ACTIVITY_DIARY_RESPONSES_INFO =
  "setActivityDiaryRepsonsesInfo";
export const RESET_ACTIVITY_DIARY_RESPONSES_INFO =
  "resetActivityDiaryRepsonsesInfo";
export const APPEND_ACTIVITY_DIARY_RESPONSES_LIST =
  "appendActivityDiaryRepsonsesInfo";
export const ADD_ACTIVITY_DIARY_RESPONSES = "addActivityDiaryRepsonses";
export const UPDATE_ACTIVITY_DIARY_RESPONSES = "updateActivityDiaryRepsonses";
export const REMOVE_ACTIVITY_DIARY_RESPONSES = "removeActivityDiaryRepsonses";
export const SET_ACTIVITY_DIARY_RESPONSES_ERRORS =
  "setActivityDiaryRepsonsesErrors";
export const RESET_ACTIVITY_DIARY_RESPONSES_ERRORS =
  "resetActivityDiaryRepsonsesErrors";

const state = {
  activity_diary_responses_errors: {},
  activity_diary_responses_list: [],
  activity_diary_responses_info: []
};

const getters = {
  activityDiaryRepsonsesErrors(state) {
    return state.activity_diary_responses_errors;
  },
  activityDiaryRepsonsesList(state) {
    return state.activity_diary_responses_list;
  },
  activityDiaryRepsonsesInfo(state) {
    return state.activity_diary_responses_info;
  }
};

const actions = {
  [POPULATE_ACTIVITY_DIARY_RESPONSES_LIST](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_diary_responses",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_diary_responses;
        context.commit(SET_ACTIVITY_DIARY_RESPONSES_LIST, responses);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_DIARY_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_DIARY_RESPONSES_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_diary_responses/" +
        payload.activityDiaryResponseId,
      {
        params: payload.params
      }
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_DIARY_RESPONSES_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_DIARY_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_ADDITIONAL_DIARY_RESPONSES_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_diary_responses",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_diary_responses;
        context.commit(
          APPEND_ACTIVITY_DIARY_RESPONSES_LIST,
          payload,
          responses
        );
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_DIARY_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_DIARY_RESPONSES](context, payload) {
    return ApiService.post(
      "api/v1/activities/" + payload.activityId + "/activity_diary_responses",
      payload.data
    )
      .then(res => {
        context.commit(ADD_ACTIVITY_DIARY_RESPONSES, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_DIARY_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [UPDATE_DIARY_RESPONSES](context, payload) {
    return ApiService.put(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_diary_responses" +
        payload.activityDiaryResponseId,
      payload.data
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_DIARY_RESPONSES_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_DIARY_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [DELETE_DIARY_RESPONSES](context, payload) {
    return ApiService.delete("api/v1/activities/" + payload.activity_id + "/activity_diary_responses/" + payload.activity_response_id)
      .then((res) => {
        Vue.$log.info("deleted");
        Vue.$log.info(res);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_DIARY_RESPONSES_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CLEAR_DIARY_RESPONSES_LIST](context) {
    context.commit(RESET_ACTIVITY_DIARY_RESPONSES_LIST);
  },
  [CLEAR_DIARY_RESPONSES_INFO](context) {
    context.commit(RESET_ACTIVITY_DIARY_RESPONSES_INFO);
  },
  [CLEAR_DIARY_RESPONSES_ERRORS](context) {
    context.commit(RESET_ACTIVITY_DIARY_RESPONSES_ERRORS);
  }
};

const mutations = {
  [SET_ACTIVITY_DIARY_RESPONSES_LIST](state, activity_diary_responses_list) {
    state.activity_diary_responses_list = activity_diary_responses_list;
  },
  [RESET_ACTIVITY_DIARY_RESPONSES_LIST](state) {
    state.activity_diary_responses_list = [];
  },
  [SET_ACTIVITY_DIARY_RESPONSES_INFO](state, activity_diary_responses_info) {
    state.activity_diary_responses_info = activity_diary_responses_info;
  },
  [RESET_ACTIVITY_DIARY_RESPONSES_INFO](state) {
    state.activity_diary_responses_info = [];
  },
  [ADD_ACTIVITY_DIARY_RESPONSES](state, activity_diary_responses_info) {
    state.activity_diary_responses_list.push(activity_diary_responses_info);
  },
  [UPDATE_ACTIVITY_DIARY_RESPONSES](state, activity_diary_responses_info) {
    let newActivityDiaryRepsonsesArray = [];
    newActivityDiaryRepsonsesArray.push(activity_diary_responses_info);
    state.activity_diary_responses_list = state.activity_diary_responses_list.map(
      obj => newActivityDiaryRepsonsesArray.find(o => o.id === obj.id) || obj
    );
  },
  [APPEND_ACTIVITY_DIARY_RESPONSES_LIST](
    state,
    payload,
    activity_diary_responses_additional_list
  ) {
    let responseToIndex = state.activity_diary_responses_list.findIndex(
      p => p.id == payload.responding_to
    ); // to be update
    if (responseToIndex !== -1) {
      state.activity_diary_responses_list = state.activity_diary_responses_list.splice(
        responseToIndex,
        0,
        activity_diary_responses_additional_list
      );
    } else {
      state.activity_diary_responses_list.push(
        activity_diary_responses_additional_list
      );
    }
  },
  [REMOVE_ACTIVITY_DIARY_RESPONSES](state, activityDiaryResponseId) {
    state.activity_diary_responses_list = state.activity_diary_responses_list.filter(
      function(obj) {
        return obj.id !== activityDiaryResponseId;
      }
    );
  },
  [SET_ACTIVITY_DIARY_RESPONSES_ERRORS](
    state,
    activity_diary_responses_errors
  ) {
    state.activity_diary_responses_errors = activity_diary_responses_errors;
  },
  [RESET_ACTIVITY_DIARY_RESPONSES_ERRORS](state) {
    state.activity_diary_responses_errors = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
