import ApiService from "@/core/services/api.service.js";

class VuexHelpers {

  static create(reference, context, url, payload, mutate = true) {
    let addObjectMutation = `add${reference}`;
    let setListMutation   = `set${reference}List`
    let addErrorMutation  = `set${reference}FormError`;

    return ApiService.post(url, payload)
      .then(res => {
        if (mutate && res.data.data != undefined) {
          let listAttribute = `${reference.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()}_list`;
          if (context.state[listAttribute] == null) {
            context.commit(setListMutation, []);
          }
          context.commit(addObjectMutation, res.data.data);
        }

        return (res.data.data != undefined) ? res.data.data : null;
      })
      .catch(error => {
        if (error.response && mutate) {
          context.commit(addErrorMutation, error.response.data);
        }
        throw error;
      });
  }

  static update(reference, context, url, payload, mutate = true) {
    let resetObjectMutation = `reset${reference}`;
    let addErrorMutation = `set${reference}FormError`;

    return ApiService.put(url, payload)
      .then(res => {
        if (mutate) {
          context.commit(resetObjectMutation, res.data.data);
        }
        return res.data.data;
      })
      .catch(error => {
        if (error.response && mutate) {
          context.commit(addErrorMutation, error.response.data);
        }
        throw error;
      });
  }

  static delete(reference, context, url, strippedId, mutate = true) {
    let stripObjectMutation = `strip${reference}`;
    let addErrorMutation = `set${reference}FormError`;

    return ApiService.delete(url)
      .then(res => {
        if (mutate) {
          context.commit(stripObjectMutation, strippedId);
        }
        return res.data.data;
      })
      .catch(error => {
        if (error.response && mutate) {
          context.commit(addErrorMutation, error.response.data);
        }
        throw error;
      });
  }

  static push(state, reference, newObject) {
    let listAttribute = `${reference}_list`;

    if (state[listAttribute] == null) {
      state[listAttribute] = [newObject];
    } else {
      state[listAttribute].push(newObject);
    }
  }

  static add(state, reference, newObject) {
    let infoAttribute = `${reference}_info`;

    this.push(state, reference, newObject);
    state[infoAttribute] = newObject;
  }

  static reset(state, reference, updatedObject) {
    let listAttribute = `${reference}_list`;
    let infoAttribute = `${reference}_info`;

    state[infoAttribute] = updatedObject;
    if (state[listAttribute] == null) {
      state[listAttribute] = [updatedObject];
    } else {
      state[listAttribute] = [
        ...state[listAttribute].map(
          existingObject => existingObject.id !== updatedObject.id ? existingObject : {...existingObject, ...updatedObject}
        )
      ]      
    }
  }

  static strip(state, reference, removedObjectId) {
    let listAttribute = `${reference}_list`;
    state[listAttribute] = state[listAttribute].filter(function( listItem ) {
      return listItem.id !== removedObjectId;
    });
  }

}

export default VuexHelpers;