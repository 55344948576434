import ApiService from "@/core/services/api.service";

// action types
export const POPULATE_RESPONSE_LIST = "populateResponseList";
export const POPULATE_FILTER_LIST = "populateFilterList";
export const POPULATE_FILTER_RESET = "populateFilterReset";
export const CLEAR_STREAM_RESULTS = "clearStreamResults";
export const STRIP_ACTIVITY_RESPONSE = "stripActivityResponse";
export const STRIP_ACTIVITY_ENGAGEMENT = "stripReplyResponse";
export const REPLACE_REPLY_ENGAGEMENT = "replaceReplyEngagement";

// mutation types
export const SET_QUESTION_RESULTS = "setQuestionResults";
export const SET_STREAM_AGGREGATES = "setStreamAggregates";
export const SET_STREAM_RESULTS = "setStreamResults";
export const RESET_FILTER_LIST = "resetFilterList";
export const SET_FILTER_LIST = "setFilterList";
export const RESET_STREAM_RESULTS = "resetStreamResults";
export const REMOVE_RESPONSE_FROM_LIST = "removeResponseFromList";
export const REMOVE_ENGAGEMENT_FROM_RESPONSE = "removeEngagementFromResponse";
export const SET_ENGAGEMENT_REPLIES = "setEngagementReply";
export const ADD_TO_FILTER = "addToFilter";
export const STRIP_FROM_FILTER = "stripFromFilter";
export const ADD_TO_CHART = "addToChart";
export const STRIP_FROM_CHART = "stripFromChart";
export const SET_LEGACY = "setLegacy";

const state = {
  loader: {
    componentsRequired: 7,
    componentsCompleted: 0,
    reloadingResponses: true,
    fetchingMoreResponses: false,
    noMoreResponses: false,
    offset: 1
  },
  filter: {
    id: null,
    search: "",
    new: false,
    dateRange: {
      startDate: null,
      endDate: null
    },
    participants: [],
    activities: [],
    questions: [],
    segments: [],
    segment_option: 1,
    tags: [],
    medias: [],
    favourite: false,
    note: false,
    reset: false,
    sort: 1,
    preLoading: 0
  },
  filterReset: { //Here be dragons
    id: null,
    search: "",
    new: false,
    dateRange: {
      startDate: null,
      endDate: null
    },
    participants: [],
    activities: [],
    questions: [],
    segments: [],
    segment_option: 1,
    tags: [],
    medias: [],
    favourite: false,
    note: false,
    reset: false,
    sort: 1,
    preLoading: 0
  },
  results: {
    aggregated: [],
    responses: [],
    questions: []
  },
  charts: {},
  legacy: 0
};

const getters = {
  streamLoader(state) {
    return state.loader;
  },
  streamFilter(state) {
    return state.filter;
  },
  streamFilterReset(state) {
    return state.filterReset;
  },
  streamResults(state) {
    return state.results;
  },
  streamCharts(state) {
    return state.charts;
  }
};

const actions = {
  [POPULATE_RESPONSE_LIST](context, payload) {

    let endpoint = (context.state.legacy) ? "stream_legacy" : "stream";

    return ApiService.get(
      "api/v1/projects/" + payload.projectId +
        "/" + endpoint, { params: payload.params }
    )
    .then(res => {
      let data = [];

      if (!Array.isArray(res.data)) {
        let dataArray = Object.entries(res.data);
        dataArray.forEach(function (item) {
          return data.push(item[1]);
        });
      } else {
        data = res.data;
      }

      context.commit(SET_QUESTION_RESULTS, data[0]);
      context.commit(SET_STREAM_AGGREGATES, data[1]);
      context.commit(SET_STREAM_RESULTS, data[2]);
      return data[2];
    })
  },
  [POPULATE_FILTER_LIST](context, payload) {
    context.commit(RESET_FILTER_LIST);
    context.commit(SET_FILTER_LIST, payload);
  },
  [CLEAR_STREAM_RESULTS](context) {
      context.commit(RESET_STREAM_RESULTS);
  },
  [STRIP_ACTIVITY_RESPONSE](context, activityResponseId) {
    context.commit(REMOVE_RESPONSE_FROM_LIST, activityResponseId);
  },
  [STRIP_ACTIVITY_ENGAGEMENT](context, payload) {
    context.commit(REMOVE_ENGAGEMENT_FROM_RESPONSE, payload);
  },
  [REPLACE_REPLY_ENGAGEMENT](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_response_replies",
      {
        params: payload.params
      }
    )
    .then(res => {
      let mutatorPayload = {
        activityResponseId: payload.activityResponseId,
        replies: res.data.data._embedded.activity_response_replies
      };
      context.commit(SET_ENGAGEMENT_REPLIES, mutatorPayload);
    })
  }
};

const mutations = {
  [SET_QUESTION_RESULTS](state, results) {
    state.results.questions = results;
  },
  [SET_STREAM_AGGREGATES](state, results) {
    state.results.aggregated = results;
  },
  [SET_STREAM_RESULTS](state, results) {
    state.results.responses.push(results);
  },
  [RESET_FILTER_LIST](state, key = null) {
    if (state.filter.preLoading == 0) {
      if (key) {
        let data = state.filter[key];
        state.filter[key] = [];
        return data;
      } else {
        let data = state.filter;
        state.filter = state.filterReset;
        return data;
      }      
    }
  },
  [SET_FILTER_LIST](state, filter) {
    for (const [key, value] of Object.entries(filter)) {
      state.filter[key] = value;
    }
  },
  [RESET_STREAM_RESULTS](state) {
    state.results = {
      aggregated: [],
      responses: [],
      questions: []
    }
  },
  [REMOVE_RESPONSE_FROM_LIST](state, activityResponseId) {
    let results = [];

    state.results.responses.forEach(function (chunkResults) {
      results.push(chunkResults.filter(function( result ) {
        return result.id !== activityResponseId;
      }));
    });

    state.results.responses = results;
  },
  [REMOVE_ENGAGEMENT_FROM_RESPONSE](state, payload) {
    state.results.responses.forEach(function (chunkResults, chunkIndex) {
      chunkResults.forEach(function (result, index) {
        if (result.id == payload.activityResponseId) {
          if (["SurveyQuestionResponse","DiaryQuestionResponse","PollQuestionResponse"].includes(payload.baseMorph)) {
            let questionKey = null;
            switch (payload.baseMorph) {
              case "SurveyQuestionResponse" :
                questionKey = "survey_question_responses";
                break;
              case "DiaryQuestionResponse" :
                questionKey = "diary_question_responses";
                break;
              case "PollQuestionResponse" :
                questionKey = "poll_question_responses";
                break;
            }
            state.results.responses[chunkIndex][index]['expandedResponse'][questionKey][payload.baseQuestionPosition][payload.type] =
              state.results.responses[chunkIndex][index]['expandedResponse'][questionKey][payload.baseQuestionPosition][payload.type].filter(function ( reply ) {
                reply.id !== payload.engagementId
              });
          } else {
            state.results.responses[chunkIndex][index]['expandedResponse'][payload.type] = state.results.responses[chunkIndex][index]['expandedResponse'][payload.type].filter(function ( reply ) {
              reply.id !== payload.engagementId
            });            
          }
        }
      })
    });
  },
  [SET_ENGAGEMENT_REPLIES](state, payload) {
    state.results.responses.forEach(function (chunkResults, chunkIndex) {
      chunkResults.forEach(function (result, index) {
        if (result.id == payload.activityResponseId) {
          state.results.responses[chunkIndex][index]['expandedResponse']['replies'] = payload.replies;
        }
      })
    });
  },
  [ADD_TO_FILTER](state, payload) {
    let existingEntries = state.filter[payload.key].filter(function( filterOption ) {
      return filterOption.id == payload.id;
    });

    if (existingEntries.length == 0) {
      let key = payload.key;
      delete payload.key;
      state.filter[key].push(payload);
    }
  },
  [STRIP_FROM_FILTER](state, payload) {
    state.filter[payload.key] = state.filter[payload.key].filter(function ( filterOption ) {
      return filterOption.id !== payload.id
    });
  },
  [ADD_TO_CHART](state, payload) {
    state.charts[payload.index] = payload.chart;
  },
  [STRIP_FROM_CHART](state, index) {
    delete state.charts[index];
  },
  [SET_LEGACY](state, legacy) {
    state.legacy = legacy;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};