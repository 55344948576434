import ApiService from "@/core/services/api.service";
import Vue from "vue";

//action types
export const POPULATE_PROJECT_SEGMENTATION_LIST = "populateProjectSegmentationList";
export const CLEAR_PROJECT_SEGMENTATION_LIST = "clearProjectSegmentationList";
export const CLEAR_PROJECT_SEGMENTATION_ERRORS = "clearProjectSegmentationErrors";

// mutation types
export const SET_PROJECT_SEGMENTATION_LIST = "setProjectSegmentationList";
export const RESET_PROJECT_SEGMENTATION_LIST = "resetProjectSegmentationList";
export const SET_PROJECT_SEGMENTATION_ERRORS = "setProjectSegmentationErrors";
export const RESET_PROJECT_SEGMENTATION_ERRORS = "resetProjectSegmentationErrors";

const state = {
  project_segmentation_list: [],
  errors: {}
};

const getters = {
  projectSegmentations(state) {
    return state.project_segmentation_list;
  },
  projectSegmentationErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_SEGMENTATION_LIST](context, projectId) {
    return ApiService.get(
      "api/v1/projects/" + projectId +
        "/project_segmentations"
    )
      .then(res => {
        const projectSegmentations = res.data.data._embedded.project_segmentations;
        context.commit(SET_PROJECT_SEGMENTATION_LIST, projectSegmentations);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_SEGMENTATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_SEGMENTATION_LIST](context) {
    context.commit(RESET_PROJECT_SEGMENTATION_LIST);
  },
  [CLEAR_PROJECT_SEGMENTATION_ERRORS](context) {
    context.commit(RESET_PROJECT_SEGMENTATION_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_SEGMENTATION_LIST](state, project_segmentations) {
    state.project_segmentation_list = project_segmentations;
  },
  [RESET_PROJECT_SEGMENTATION_LIST](state) {
    state.project_segmentation_list = {};
  },
  [SET_PROJECT_SEGMENTATION_ERRORS](state,errors) {
    state.errors = errors;
  },
  [RESET_PROJECT_SEGMENTATION_ERRORS](state) {
    state.errors = {};
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};