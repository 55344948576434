import ApiService from "@/core/services/api.service";

// action types
export const POPULATE_ACTIVITY_NOTIFICATIONS = "populateActivityNotifications";
export const POPULATE_ACTIVITY_SETTINGS = "populateActivitySettings";
export const POPULATE_ACTIVITY_STAGES = "populateActivityStages";
export const POPULATE_ACTIVITY_TYPES = "populateActivityTypes";
export const POPULATE_ADMIN_ROLES = "populateAdminRoles";
export const POPULATE_CLIENT_ROLES = "populateClientRoles";
export const POPULATE_COUNTRIES = "populateCountries";
export const POPULATE_GENDERS = "populateGenders";
export const POPULATE_LANGUAGES = "populateLanguages";
export const POPULATE_MARKUP_PIN_TYPES = "populateMarkupPinTypes";
export const POPULATE_NOTIFY_METHODS = "populateNotifyMethods";
export const POPULATE_PROJECT_ROLES = "populateProjectRoles";
export const POPULATE_PROJECT_STAGES = "populateProjectStages";
export const POPULATE_ROOM_SETUPS = "populateRoomSetups";
export const POPULATE_SEGMENT_CLASSES = "populateSegmentClasses";
export const POPULATE_SOCIAL_BIAS = "populateSocialBias";
export const POPULATE_SOCIAL_SETTING = "populateSocialSetting";
export const POPULATE_TIMEZONES = "populateTimezones";
export const POPULATE_WIDGET_TYPES = "populateWidgetTypes";

// mutation types
export const SET_ACTIVITY_NOTIFICATIONS = "setActivityNotifications";
export const SET_ACTIVITY_SETTINGS = "setActivitySettings";
export const SET_ACTIVITY_STAGES = "setActivityStages";
export const SET_ACTIVITY_TYPES = "setActivityTypes";
export const SET_ADMIN_ROLES = "setAdminRoles";
export const SET_CLIENT_ROLES = "setClientRoles";
export const SET_COUNTRIES = "setCountries";
export const SET_GENDERS = "setGenders";
export const SET_LANGUAGES = "setLanguages";
export const SET_MARKUP_PIN_TYPES = "setMarkupPinTypes";
export const SET_NOTIFY_METHODS = "setNotifyMethods";
export const SET_PROJECT_ROLES = "setProjectRoles";
export const SET_PROJECT_STAGES = "setProjectStages";
export const SET_ROOM_SETUPS = "setRoomSetups";
export const SET_SEGMENT_CLASSES = "setSegmentClasses";
export const SET_SOCIAL_BIAS = "setSocialBias";
export const SET_SOCIAL_SETTING = "setSocialSetting";
export const SET_TIMEZONES = "setTimezones";
export const SET_WIDGET_TYPES = "setWidgetTypes";

const state = {
  activity_notifications: [],
  activity_settings: [],
  activity_stages: [],
  activity_types: [],
  admin_roles: [],
  client_roles: [],
  countries: [],
  genders: [],
  languages: [],
  markup_pin_types: [],
  notifiy_methods: [],
  project_roles: [],
  project_stages: [],
  room_setups: [],
  segment_classes: [],
  social_bias: [],
  social_setting: [],
  timezones: [],
  widget_types: []
};

const getters = {
  systemActivityNotifications(state) {
    return state.activity_notifications;
  },
  systemActivitySettings(state) {
    return state.activity_settings;
  },
  systemActivityStages(state) {
    return state.activity_stages;
  },
  systemActivityTypes(state) {
    return state.activity_types;
  },
  systemAdminRoles(state) {
    return state.admin_roles;
  },
  systemClientRoles(state) {
    return state.client_roles;
  },
  systemCountries(state) {
    return state.countries;
  },
  systemGenders(state) {
    return state.genders;
  },
  systemLanguages(state) {
    return state.languages;
  },
  systemMarkupPinTypes(state) {
    return state.markup_pin_types;
  },
  systemNotifyMethods(state) {
    return state.notifiy_methods;
  },
  systemProjectRoles(state) {
    return state.project_roles;
  },
  systemProjectStages(state) {
    return state.project_stages;
  },
  systemRoomSetups(state) {
    return state.room_setups;
  },
  systemSegmentClasses(state) {
    return state.segment_classes;
  },
  systemSocialBias(state) {
    return state.social_bias;
  },
  systemSocialSetting(state) {
    return state.social_setting;
  },
  systemTimezones(state) {
    return state.timezones;
  },
  systemWidgetTypes(state) {
    return state.widget_types;
  }
};

const actions = {
  [POPULATE_ACTIVITY_NOTIFICATIONS](context, paginate) {
    return ApiService.get("api/v1/system_activity_notifications", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let activity_notifictions =
        res.data.data._embedded.system_activity_notifications;
      context.commit(SET_ACTIVITY_NOTIFICATIONS, activity_notifictions);
    });
  },
  [POPULATE_ACTIVITY_SETTINGS](context, paginate) {
    return ApiService.get("api/v1/system_activity_settings", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let activity_settings = res.data.data._embedded.system_activity_settings;
      context.commit(SET_ACTIVITY_SETTINGS, activity_settings);
    });
  },
  [POPULATE_ACTIVITY_STAGES](context, paginate) {
    return ApiService.get("api/v1/system_activity_stages", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let activity_stages = res.data.data._embedded.system_activity_stages;
      context.commit(SET_ACTIVITY_STAGES, activity_stages);
    });
  },
  [POPULATE_ACTIVITY_TYPES](context, paginate) {
    return ApiService.get("api/v1/system_activity_types", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let activity_types = res.data.data._embedded.system_activity_types;
      context.commit(SET_ACTIVITY_TYPES, activity_types);
    });
  },
  [POPULATE_ADMIN_ROLES](context, paginate) {
    return ApiService.get("api/v1/system_admin_roles", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let admin_roles = res.data.data._embedded.system_admin_roles;
      context.commit(SET_ADMIN_ROLES, admin_roles);
    });
  },
  [POPULATE_CLIENT_ROLES](context, paginate) {
    return ApiService.get("api/v1/system_client_roles", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let client_roles = res.data.data._embedded.system_client_roles;
      context.commit(SET_CLIENT_ROLES, client_roles);
    });
  },
  [POPULATE_COUNTRIES](context, paginate) {
    return ApiService.get("api/v1/system_countries", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let countries = res.data.data._embedded.system_countries;
      context.commit(SET_COUNTRIES, countries);
    });
  },
  [POPULATE_GENDERS](context, paginate) {
    return ApiService.get("api/v1/system_genders", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let genders = res.data.data._embedded.system_genders;
      context.commit(SET_GENDERS, genders);
    });
  },
  [POPULATE_LANGUAGES](context, paginate) {
    return ApiService.get("api/v1/system_languages", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let languages = res.data.data._embedded.system_languages;
      context.commit(SET_LANGUAGES, languages);
    });
  },
  [POPULATE_MARKUP_PIN_TYPES](context) {
    return ApiService.get("api/v1/system_markup_pin_types", {
      params: {
        paginate: false
      }
    }).then(res => {
      let pin_types = res.data.data._embedded.system_markup_pin_types;
      context.commit(SET_MARKUP_PIN_TYPES, pin_types);
    }); 
  },
  [POPULATE_NOTIFY_METHODS](context, paginate) {
    return ApiService.get("api/v1/system_notify_methods", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let notify_methods = res.data.data._embedded.system_notify_methods;
      context.commit(SET_NOTIFY_METHODS, notify_methods);
    });
  },
  [POPULATE_PROJECT_ROLES](context, paginate) {
    return ApiService.get("api/v1/system_project_roles", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let project_roles = res.data.data._embedded.system_project_roles;
      context.commit(SET_PROJECT_ROLES, project_roles);
    });
  },
  [POPULATE_PROJECT_STAGES](context, paginate) {
    return ApiService.get("api/v1/system_project_stages", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let project_stages = res.data.data._embedded.system_project_stages;
      context.commit(SET_PROJECT_STAGES, project_stages);
    });
  },
  [POPULATE_ROOM_SETUPS](context, paginate) {
    return ApiService.get("api/v1/system_room_setups", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let room_setups = res.data.data._embedded.system_room_setups;
      context.commit(SET_ROOM_SETUPS, room_setups);
    });
  },
  [POPULATE_SEGMENT_CLASSES](context, paginate) {
    return ApiService.get("api/v1/system_segment_classes", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let segment_classes = res.data.data._embedded.system_segment_classes;
      context.commit(SET_SEGMENT_CLASSES, segment_classes);
    });
  },
  [POPULATE_SOCIAL_BIAS](context, paginate) {
    return ApiService.get("api/v1/system_social_bias", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let social_bias = res.data.data._embedded.system_social_bias;
      context.commit(SET_SOCIAL_BIAS, social_bias);
    });
  },
  [POPULATE_SOCIAL_SETTING](context, paginate) {
    return ApiService.get("api/v1/system_social_settings", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let social_settings = res.data.data._embedded.system_social_settings;
      context.commit(SET_SOCIAL_SETTING, social_settings);
    });
  },
  [POPULATE_TIMEZONES](context, paginate) {
    return ApiService.get("api/v1/system_timezones", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let timezones = res.data.data._embedded.system_timezones;
      context.commit(SET_TIMEZONES, timezones);
    });
  },
  [POPULATE_WIDGET_TYPES](context, paginate) {
    return ApiService.get("api/v1/system_widget_types", {
      params: {
        paginate: paginate
      }
    }).then(res => {
      let widget_types = res.data.data._embedded.system_widget_types;
      context.commit(SET_WIDGET_TYPES, widget_types);
    });
  }
};

const mutations = {
  [SET_ACTIVITY_NOTIFICATIONS](state, activity_notifictions) {
    state.activity_notifictions = activity_notifictions;
  },
  [SET_ACTIVITY_SETTINGS](state, activity_settings) {
    state.activity_settings = activity_settings;
  },
  [SET_ACTIVITY_STAGES](state, activity_stages) {
    state.activity_stages = activity_stages;
  },
  [SET_ACTIVITY_TYPES](state, activity_types) {
    state.activity_types = activity_types;
  },
  [SET_ADMIN_ROLES](state, admin_roles) {
    state.admin_roles = admin_roles;
  },
  [SET_CLIENT_ROLES](state, client_roles) {
    state.client_roles = client_roles;
  },
  [SET_COUNTRIES](state, countries) {
    state.countries = countries;
  },
  [SET_GENDERS](state, genders) {
    state.genders = genders;
  },
  [SET_LANGUAGES](state, languages) {
    state.languages = languages;
  },
  [SET_MARKUP_PIN_TYPES](state, markupPinTypes) {
    state.markup_pin_types = markupPinTypes;
  },
  [SET_NOTIFY_METHODS](state, notifiy_methods) {
    state.notifiy_methods = notifiy_methods;
  },
  [SET_PROJECT_ROLES](state, project_roles) {
    state.project_roles = project_roles;
  },
  [SET_PROJECT_STAGES](state, project_stages) {
    state.project_stages = project_stages;
  },
  [SET_ROOM_SETUPS](state, room_setups) {
    state.room_setups = room_setups;
  },
  [SET_SEGMENT_CLASSES](state, segment_classes) {
    state.segment_classes = segment_classes;
  },
  [SET_SOCIAL_BIAS](state, social_bias) {
    state.social_bias = social_bias;
  },
  [SET_SOCIAL_SETTING](state, social_setting) {
    state.social_setting = social_setting;
  },
  [SET_TIMEZONES](state, timezones) {
    state.timezones = timezones;
  },
  [SET_WIDGET_TYPES](state, widget_types) {
    state.widget_types = widget_types;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
