import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_DIARY_QUESTION_LIST = "populateDiaryQuestionList";
export const POPULATE_DIARY_QUESTION_INFO = "populateDiaryQuestionInfo";
export const CREATE_DIARY_QUESTION = "createDiaryQuestion";
export const UPDATE_DIARY_QUESTION = "updateDiaryQuestion";
export const DELETE_DIARY_QUESTION = "deleteDiaryQuestion";
export const CLEAR_DIARY_QUESTION_LIST = "clearDiaryQuestionList";
export const CLEAR_DIARY_QUESTION_INFO = "clearDiaryQuestionInfo";
export const CLEAR_DIARY_QUESTION_ERRORS = "clearDiaryQuestionErrors";

// mutation types
export const SET_DIARY_QUESTION_LIST = "setDiaryQuestionList";
export const RESET_DIARY_QUESTION_LIST = "resetDiaryQuestionList";
export const SET_DIARY_QUESTION_LIST_META = "setDiaryQuestionListMeta";
export const RESET_DIARY_QUESTION_LIST_META = "resetDiaryQuestionListMeta";
export const SET_DIARY_QUESTION_INFO = "setDiaryQuestionInfo";
export const RESET_DIARY_QUESTION_INFO = "resetDiaryQuestionInfo";
export const SET_DIARY_QUESTION_ERRORS = "setDiaryQuestionErrors";
export const RESET_DIARY_QUESTION_ERRORS = "resetDiaryQuestionErrors";
export const ADD_NEW_DIARY_QUESTION = "addNewDiaryQuestion";
export const UPDATE_QUESTION = "updateQuestion";
export const REMOVE_DIARY_QUESTION = "removeDiaryQuestion";

const state = {
  diary_question_errors: {},
  diary_question_list: [],
  diary_question_list_meta: {},
  diary_question_info: []
};

const getters = {
  diaryQuestionErrors(state) {
    return state.diary_question_errors;
  },
  diaryQuestionList(state) {
    return state.diary_question_list;
  },
  diaryQuestionListMeta(state) {
    return state.diary_question_list_meta;
  },
  diaryQuestionInfo(state) {
    return state.diary_question_info;
  }
};

const actions = {
  [POPULATE_DIARY_QUESTION_LIST](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_diary_questions",
      {
        params: payload.params
      }
    )
      .then(res => {
        const diaryQuestions = res.data.data._embedded.activity_diary_questions;
        context.commit(SET_DIARY_QUESTION_LIST, diaryQuestions);
        if (res.data.meta != undefined) {
          context.commit(SET_DIARY_QUESTION_LIST_META, res.data.meta);
        }
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_DIARY_QUESTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_DIARY_QUESTION_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_diary_questions/" +
        payload.id,
      {
        params: payload.params
      }
    )
      .then(res => {
        const diaryQuestion = res.data.data;
        context.commit(SET_DIARY_QUESTION_INFO, diaryQuestion);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_DIARY_QUESTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_DIARY_QUESTION](context, payload) {
    return ApiService.post(
      "api/v1/activities/" + payload.activityId + "/activity_diary_questions",
      payload.data
    )
      .then(res => {
        context.commit(ADD_NEW_DIARY_QUESTION, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_DIARY_QUESTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_DIARY_QUESTION](context, payload) {
    return ApiService.put(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_diary_questions/" +
        payload.id,
      payload.data
    )
      .then(res => {
        context.commit(UPDATE_QUESTION, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_DIARY_QUESTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_DIARY_QUESTION](context, payload) {
    return ApiService.delete(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_diary_questions/" +
        payload.id
    )
      .then(res => {
        context.commit(REMOVE_DIARY_QUESTION, payload.id);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_DIARY_QUESTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_DIARY_QUESTION_LIST](context) {
    context.commit(RESET_DIARY_QUESTION_LIST);
    context.commit(RESET_DIARY_QUESTION_LIST_META);
  },
  [CLEAR_DIARY_QUESTION_INFO](context) {
    context.commit(RESET_DIARY_QUESTION_INFO);
  },
  [CLEAR_DIARY_QUESTION_ERRORS](context) {
    context.commit(RESET_DIARY_QUESTION_ERRORS);
  }
};

const mutations = {
  [SET_DIARY_QUESTION_LIST](state, diaryQuestions) {
    // let arr = [];
    // for (let index = 0; index < diaryQuestions.length; index++) {
    //   const element = diaryQuestions[index];
    //   arr.push(element);
    //   const nexElement = diaryQuestions[index + 1];
    //   if (nexElement) {
    //     if (element.page < nexElement.page) {
    //       arr.push({ id: null, is_page_break: true });
    //     }
    //   }
    // }
    state.diary_question_list = diaryQuestions;
  },
  [RESET_DIARY_QUESTION_LIST](state) {
    state.diary_question_list = [];
  },
  [SET_DIARY_QUESTION_LIST_META](state, diary_question_list_meta) {
    state.diary_question_list_meta = diary_question_list_meta;
  },
  [RESET_DIARY_QUESTION_LIST_META](state) {
    state.diary_question_list_meta = {};
  },
  [SET_DIARY_QUESTION_INFO](state, diaryQuestion) {
    state.diary_question_info = diaryQuestion;
  },
  [RESET_DIARY_QUESTION_INFO](state) {
    state.diary_question_info = [];
  },
  [ADD_NEW_DIARY_QUESTION](state, diaryQuestion) {
    state.diary_question_list.push(diaryQuestion);
  },
  [UPDATE_QUESTION](state, diaryQuestion) {
    let newDiaryQuestionArray = [];
    newDiaryQuestionArray.push(diaryQuestion);
    state.diary_question_list = state.diary_question_list.map(
      obj => newDiaryQuestionArray.find(o => o.id === obj.id) || obj
    );
  },
  [REMOVE_DIARY_QUESTION](state, diaryQuestionId) {
    state.diary_question_list = state.diary_question_list.filter(function(obj) {
      return obj.id !== diaryQuestionId;
    });
  },
  [SET_DIARY_QUESTION_ERRORS](state, errors) {
    state.diary_question_errors = errors;
  },
  [RESET_DIARY_QUESTION_ERRORS](state) {
    state.diary_question_errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
