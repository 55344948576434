import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";

// API service init
import ApiService from "@/core/services/api.service";
ApiService.init();

import { VERIFY_AUTH } from "@/core/services/store/system/auth.module";

Vue.config.productionTip = false;

// Global Components
import Replies from "@/modules/together-helpers/activities/response/Replies";
Vue.component("Replies", Replies);

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);
import VueSlimScroll from "vue-slimscroll";
Vue.use(VueSlimScroll);
import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";

Vue.use(ElementUI, { locale });
// import Lodash
import VueLodash from "vue-lodash";
import lodash from "lodash";

// import resourceful vuex action
import { GENERIC_RESOURCE } from "@/modules/together-helpers/vuex/generic.module";
window.GENERIC_RESOURCE = GENERIC_RESOURCE;

// name is optional
Vue.use(VueLodash, { name: "custom", lodash: lodash });

//Global Components
import AnonymisedUsername from "@/modules/together-helpers/components/generic-displays/GenericUserName";
import AnonymisedEmail from "@/modules/together-helpers/components/generic-displays/GenericUserEmail";

Vue.component('AnonymisedUsername', AnonymisedUsername);
Vue.component('AnonymisedEmail', AnonymisedEmail);

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload, {
  observer: true,
  // optional
  observerOptions: {
    rootMargin: "0px",
    threshold: 0.1
  }
});
import VueGtm from "vue-gtm";
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
});
import VueLogger from "vuejs-logger";

const isProduction = process.env.NODE_ENV === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};

Vue.use(VueLogger, options);

// Vue Loading Spinner init
import Loading from "vue-loading-overlay";

import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading, {
  color: "#f96332",
  loader: "dots"
});

// Vue Toasted init
import Toasted from "vue-toasted";
const ToastedOptions = {
  position: "bottom-center", //['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
  iconPack: "custom-class",
  duration: "3000",
  keepOnHover: true,
  fitToScreen: true
};

Vue.use(Toasted, ToastedOptions);


Vue.toasted.register(
  "success_toast", // name
  (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
      return "Action Completed";
    }

    // if there is a message show it with the message
    return payload.message;
  },
  {
    type: "success",
    icon: { name: "now-ui-icons ui-1_check" }
  }
);

Vue.toasted.register(
  "success_toast", // name
  payload => {
    // if there is no message passed show default message
    if (!payload.message) {
      return "Action Completed";
    }

    // if there is a message show it with the message
    return payload.message;
  },
  {
    type: "success",
    icon: { name: "now-ui-icons ui-1_check" }
  }
);

Vue.toasted.register(
  "error_toast", // name
  payload => {
    if (!payload.message) {
      return " Something Went Wrong..";
    }

    return payload.message;
  },
  {
    type: "error",
    icon: { name: "now-ui-icons ui-1_bell-53" }
  }
);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

//v-dragged directive
import VDragged from "v-dragged";

Vue.use(VDragged);

//vue-video-player
import VueVideoPlayer from "vue-video-player";
// require videojs style
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";

Vue.use(VueVideoPlayer);

Vue.filter("stripHTML", function(value) {
  const div = document.createElement("div");
  div.innerHTML = value;
  const text = div.textContent || div.innerText || "";
  return text;
});

// Vue Router init
/* eslint-disable */
router.beforeEach(async (to, from, next) => {
  let proj_client_key = window.location.host.split(".")[0];
  
  if (to.name == "login") {
    let vue3host = process.env.VUE_APP_UPGRADE_URL_SUFFIX;
    let redirectUrl = vue3host + "/#/sign_in/" + proj_client_key + to.fullPath.split("/login").pop();
    window.location.replace(redirectUrl);
  }else if (to.fullPath.match(/^\/help(?:\/(?=$))?$/i) !== null) {
    let vue3host = process.env.VUE_APP_UPGRADE_URL_SUFFIX;
    let redirectUrl = vue3host + "/#" + to.fullPath;
    if (proj_client_key !== "admin") {
      redirectUrl = process.env.VUE_APP_UPGRADE_URL_SUFFIX + "/#/help/" + proj_client_key;
    }
    window.location.replace(redirectUrl);
  } else if (to.fullPath.match(/^\/register\//i) !== null) {
    let vue3host = process.env.VUE_APP_UPGRADE_URL_SUFFIX;
    let redirectUrl = vue3host + "/#" + to.fullPath + "/" + proj_client_key;
    window.location.replace(redirectUrl);
  } else if (to.fullPath.match(/^\/reset_password\//i) !== null) {
    let vue3host = process.env.VUE_APP_UPGRADE_URL_SUFFIX;
    let redirectUrl = vue3host + "/#" + to.fullPath;
    window.location.replace(redirectUrl);
  } else {
    let vue3host = process.env.VUE_APP_UPGRADE_URL_SUFFIX;
    let redirectUrl = vue3host + "/#/sign_in/" + proj_client_key;
    window.location.replace(redirectUrl);
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
/* eslint-enable */

//HERE BE DRAGONS - I'm not proud of this, temporary measure until this become a full SPA
window.setInterval(function(){
  if (window.location.hash.length == 0) {
    location.reload();
  }
}, 1000);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
