import VuexHelpers from "@/modules/together-helpers/vuex/vuex-helpers.js";
import EngagementHelpers from "@/modules/together-helpers/vuex/engagement-helpers.js";

//action types
export const CREATE_LEGACY_ACTIVITY_RESPONSE  = "createLegacyActivityResponse";
export const DELETE_LEGACY_ACTIVITY_RESPONSE  = "deleteLegacyActivityResponse";

//mutation types
export const SET_ACTIVITY_RESPONSE_LIST           = "setActivityResponseList";
export const SET_ACTIVITY_RESPONSE_SUMMARY        = "setActivityResponseSummary";
export const SET_ACTIVITY_RESPONSE_DISPLAY_ERROR  = "setActivityResponseDisplayError";
export const SET_ACTIVITY_RESPONSE_FORM_ERROR     = "setActivityResponseFormError";
export const ADD_ACTIVITY_RESPONSE                = "addActivityResponse";
export const STRIP_ACTIVITY_RESPONSE              = "stripActivityResponse";
export const ADD_ACTIVITY_RESPONSE_ENGAGEMENT     = "addActivityResponseEngagement";
export const STRIP_ACTIVITY_RESPONSE_ENGAGEMENT   = "stripActivityResponseEngagement";

//WILL DEPRECATE
export const STRIP_ACTIVITY_PICTURE_BOOK_RESPONSE       = "stripActivityPictureBookResponse";
export const STRIP_ACTIVITY_BASE_PICTURE_BOOK_RESPONSE  = "stripActivityBasePictureBookResponse";

const state = {
  activity_response_list: null,
  activity_response_summary: null,
  activity_response_display_errors: null,
  activity_response_form_errors: null
};

const getters = {
  activityResponseList(state) {
    return state.activity_response_list;
  },
  activityResponseSummary(state) {
    return state.activity_response_summary;
  },
  activityResponseDisplayErrors(state) {
    return state.activity_response_display_errors;
  },
  activityResponseFormErrors(state) {
    return state.activity_response_form_errors;
  }
};

const actions = {
  [CREATE_LEGACY_ACTIVITY_RESPONSE](context, payload) {
    payload.response = { ...payload.response, ...{ x_attributes: ['engagementSummary' ] } };
    let url = `api/v1/activities/${payload.activityId}/activity_${payload.activityType}_responses`;
    return VuexHelpers.create("ActivityResponse", context, url, payload.response);
  },
  [DELETE_LEGACY_ACTIVITY_RESPONSE](context, payload) {
    let url = `api/v1/activities/${payload.activityId}/activity_${payload.activityType}_responses/${payload.activityResponseId}`;
    return VuexHelpers.delete("ActivityResponse", context, url, payload.activityResponseId, (payload.shouldMutate == undefined) ? true : payload.shouldMutate);
  }
};

const mutations = {
  [SET_ACTIVITY_RESPONSE_LIST](state, activity_response_list = null) {
    state.activity_response_list = activity_response_list;
  },
  [SET_ACTIVITY_RESPONSE_SUMMARY](state, activity_response_summary) {
    state.activity_response_summary = activity_response_summary;
  },
  [SET_ACTIVITY_RESPONSE_DISPLAY_ERROR](state, errors = null) {
    state.activity_response_display_errors = errors;
  },
  [SET_ACTIVITY_RESPONSE_FORM_ERROR](state, errors = null) {
    state.activity_response_form_errors = errors;
  },
  [ADD_ACTIVITY_RESPONSE](state, activity_response) {
    EngagementHelpers.addActivityResponse(state, activity_response);
  },
  [STRIP_ACTIVITY_RESPONSE](state, removedActivityResponseId) {
    VuexHelpers.strip(state, 'activity_response', removedActivityResponseId);
  },
  [ADD_ACTIVITY_RESPONSE_ENGAGEMENT](state, activityResponseEngagement) {
    let response = state.activity_response_list.find(
      obj => obj.activity_response_id === activityResponseEngagement.activity_response_id
    );

    if (response) {
      EngagementHelpers.addBasicEngagement(state, response, activityResponseEngagement);
    }
  },
  [STRIP_ACTIVITY_RESPONSE_ENGAGEMENT](state, activityResponseEngagement) {
    let response = state.activity_response_list.find(obj => 
      obj.activity_response_id === activityResponseEngagement.activity_response_id
    );

    if (response) {
      EngagementHelpers.stripBasicEngagement(state, response, activityResponseEngagement);
    }
  },
  [STRIP_ACTIVITY_PICTURE_BOOK_RESPONSE](state, payload) {
    let index = state.activity_response_list.findIndex(response => response.activity_response_id === payload.activity_response_id);

    if (index !== -1) {
      state.activity_response_list[index].picture_book_responses = state.activity_response_list[index].picture_book_responses.filter(function(pictureBookResponse) {
        return pictureBookResponse.id != payload.id;
      });
    }
  },
  [STRIP_ACTIVITY_BASE_PICTURE_BOOK_RESPONSE](state, activityResponseId) {
    state.activity_response_list = state.activity_response_list.filter(function(response) {
      return response.activity_response_id != activityResponseId;
    })
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};