import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_PROJECT_ROOM_SETUP_LIST = "populateProjectRoomSetupList";

// mutation types
export const SET_PROJECT_ROOM_SETUP_LIST = "setProjectRoomSetupList"
export const SET_PROJECT_ROOM_SETUP_ERRORS = "setProjectRoomSetupErrors";

const state = {
  project_room_setup_list: [],
};

const getters = {
  projectRoomSetupList(state) {
    return state.project_room_setup_list
  }
};

const actions = {
  [POPULATE_PROJECT_ROOM_SETUP_LIST](context, payload) {
    return ApiService.get(
      "api/v1/projects/" +
        payload.projectId +
        "/project_room_setups", payload.data
    )
      .then(res => {
        const projectRoomSetups = res.data.data._embedded.project_room_setups;
        context.commit(SET_PROJECT_ROOM_SETUP_LIST, projectRoomSetups);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_ROOM_SETUP_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  }
};

const mutations = {
  [SET_PROJECT_ROOM_SETUP_LIST](state, projectRoomSetups) {
    state.project_room_setup_list = projectRoomSetups;
  },
  [SET_PROJECT_ROOM_SETUP_ERRORS](state, errors) {
    state.errors = errors;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};