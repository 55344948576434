import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ARTICLES = "populateArticles";
export const CREATE_HELP_TICKET = "createHelpTicket";

// mutation types
export const SET_HELP_ARTICLES = "setHelpArticles";
export const SET_HELP_ERRORS = "setHelpErrors";
export const RESET_HELP_FORM = "resetHelpForm";

const state = {
  help_errors: {},
  help_form: {
    email: null,
    subject: null,
    description: null,
    type_of_issue: 1,
    language: null,
    submissionStates: {
      submitting: false
    }
  },
  help_articles: []
};

const getters = {
  helpErrors(state) {
    return state.help_errors;
  },
  helpForm(state) {
    return state.help_form;
  },
  helpArticles(state) {
    return state.help_articles;
  }
};

const actions = {
  [POPULATE_ARTICLES](context, payload) {
    return ApiService.get("api/v1/zendesk", {
      params: payload.params
    }).then(res => {
      context.commit(SET_HELP_ARTICLES, res.data);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_HELP_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error);
      }
      throw error;
    });
  },
  [CREATE_HELP_TICKET](context, payload) {
    return ApiService.post("api/v1/zendesk/", payload.data)
      .then(res => {
        return res;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_HELP_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  }
};

const mutations = {
  [SET_HELP_ARTICLES](state, articles) {
    state.help_articles = articles;
  },
  [SET_HELP_ERRORS](state, errors) {
    state.help_errors = errors;
  },
  [RESET_HELP_FORM](state) {
    state.help_form = {
      email: null,
      subject: null,
      description: null,
      type_of_issue: 1,
      language: null,
      submissionStates: {
        submitting: false
      }
    };
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
