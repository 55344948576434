import VuexHelpers from "../../helpers/VuexHelpers";

// action types
export const UPDATE_PROJECT_BRAND         = "updateProjectBrand";
export const BUILD_PROJECT_BRAND          = "buildProjectBrand";

// mutation types
export const SET_PROJECT_BRAND_LIST       = "setProjectBrandList";
export const SET_PROJECT_BRAND_OBJECT     = "setProjectBrandObject";
export const SET_PROJECT_BRAND_ERRORS     = "setProjectBrandErrors";
export const SET_PROJECT_BRAND_FORM_ENTRY = "setProjectBrandFormEntry";
export const RESET_PROJECT_BRAND          = "resetProjectBrand";

const state = {
  project_brand_list: [],
  project_brand_object: {},
  project_brand_info: null,
  project_brand_errors: null,
  project_brand_form: {},
};

const getters = {
  projectBrandList(state) {
    return state.project_brand_list;
  },
  projectBrandObject(state) {
    return state.project_brand_object;
  },
  projectBrandInfo(state) {
    return state.project_brand_info;
  },
  projectBrandErrors(state) {
    return state.project_brand_errors;
  },
  projectBrandForm(state) {
    return state.project_brand_form;
  }
};

const actions = {
  [UPDATE_PROJECT_BRAND](context, payload) {
    let url = `api/v1/projects/${payload.projectId}/project_brands`;
    return VuexHelpers.create("ProjectBrand", context, url, payload, false);
  },
  [BUILD_PROJECT_BRAND](context, projectBrandList) {
    projectBrandList.forEach(function (projectBrand) {
      switch (projectBrand.config.type) {
        case "asset":
          context.commit(SET_PROJECT_BRAND_FORM_ENTRY, {
            key: projectBrand.key,
            existing_asset: projectBrand.project_asset,
            image_remove: null,
            image_add: null,
            value: projectBrand.value,
            options: JSON.parse(projectBrand.options)
          });
          break;
        case "boolean":
        case "int":
        case "url":
        case "rich":
        case "check":
          context.commit(SET_PROJECT_BRAND_FORM_ENTRY, {
            key: projectBrand.key,
            value: projectBrand.value,
            options: JSON.parse(projectBrand.options)
          });
          break;
      }
    });
  }
}

const mutations = {
  [SET_PROJECT_BRAND_LIST](state, project_brand_list) {
    state.project_brand_list = project_brand_list;
  },
  [SET_PROJECT_BRAND_OBJECT](state, project_brand_list) {
    let obj = {};

    project_brand_list.forEach(function(projectBrand) {
      obj[projectBrand.key] = projectBrand;
    });

    state.project_brand_object = obj;
  },
  [SET_PROJECT_BRAND_ERRORS](state, errors = null) {
    state.project_brand_errors = errors;
  },
  [SET_PROJECT_BRAND_FORM_ENTRY](state, entry) {
    state.project_brand_form[entry.key] = entry;
  },
  [RESET_PROJECT_BRAND](state, updatedProjectBrand) {
    VuexHelpers.reset(state, 'project_brand', updatedProjectBrand);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};