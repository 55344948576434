import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ACTIVITY_REPLY_ENGAGEMENTS_LIST =
  "populateActivityReplyEngagementsList";
export const LOAD_MORE_ACTIVITY_REPLY_ENGAGEMENTS_LIST =
  "loadMoreActivityReplyEngagementsList";
export const POPULATE_ACTIVITY_REPLY_ENGAGEMENTS_INFO =
  "populateActivityReplyEngagementsInfo";
export const CREATE_REPLY_ENGAGEMENTS = "createReplyEngagements";
export const UPDATE_REPLY_ENGAGEMENTS = "updateReplyEngagements";
export const DELETE_REPLY_ENGAGEMENTS = "deleteReplyEngagements";
export const CLEAR_REPLY_ENGAGEMENTS_LIST = "clearReplyEngagementsList";
export const CLEAR_REPLY_ENGAGEMENTS_INFO = "clearReplyEngagementsInfo";
export const CLEAR_REPLY_ENGAGEMENTS_ERRORS = "clearReplyEngagementsErrors";

// mutation types
export const SET_ACTIVITY_REPLY_ENGAGEMENTS_LIST =
  "setActivityReplyEngagementsList";
export const APPEND_ACTIVITY_REPLY_ENGAGEMENTS_LIST =
  "appendActivityReplyEngagementsList";
export const RESET_ACTIVITY_REPLY_ENGAGEMENTS_LIST =
  "resetActivityReplyEngagementsList";
export const SET_ACTIVITY_REPLY_ENGAGEMENTS_INFO =
  "setActivityReplyEngagementsInfo";
export const RESET_ACTIVITY_REPLY_ENGAGEMENTS_INFO =
  "resetActivityReplyEngagementsInfo";
export const ADD_ACTIVITY_REPLY_ENGAGEMENTS = "addActivityReplyEngagements";
export const UPDATE_ACTIVITY_REPLY_ENGAGEMENTS =
  "updateActivityReplyEngagements";
export const REMOVE_ACTIVITY_REPLY_ENGAGEMENTS =
  "removeActivityReplyEngagements";
export const SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS =
  "setActivityReplyEngagementsErrors";
export const RESET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS =
  "resetActivityReplyEngagementsErrors";

const state = {
  activity_reply_engagements_errors: {},
  activity_reply_engagements_list: [],
  activity_reply_engagements_info: []
};

const getters = {
  activityReplyEngagementsErrors(state) {
    return state.activity_reply_engagements_errors;
  },
  activityReplyEngagementsList(state) {
    return state.activity_reply_engagements_list;
  },
  activityReplyEngagementsInfo(state) {
    return state.activity_reply_engagements_info;
  }
};

const actions = {
  [POPULATE_ACTIVITY_REPLY_ENGAGEMENTS_LIST](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_response_replies",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_response_replies;
        context.commit(SET_ACTIVITY_REPLY_ENGAGEMENTS_LIST, [
          payload,
          responses
        ]);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [LOAD_MORE_ACTIVITY_REPLY_ENGAGEMENTS_LIST](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_response_replies",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_response_replies;
        context.commit(APPEND_ACTIVITY_REPLY_ENGAGEMENTS_LIST, [
          payload,
          responses
        ]);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_REPLY_ENGAGEMENTS_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_replies" +
        payload.activityReplyEngagementId,
      {
        params: payload.params
      }
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_REPLY_ENGAGEMENTS_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_REPLY_ENGAGEMENTS](context, payload) {
    return ApiService.post(
      "api/v1/activities/" + payload.activityId + "/activity_response_replies",
      payload.data
    )
      .then(res => {
        context.commit(ADD_ACTIVITY_REPLY_ENGAGEMENTS, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [UPDATE_REPLY_ENGAGEMENTS](context, payload) {
    return ApiService.put(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_replies/" +
        payload.responseId,
      payload.data
    )
      .then(res => {
        const response = res.data.data;
        context.commit(UPDATE_ACTIVITY_REPLY_ENGAGEMENTS, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [DELETE_REPLY_ENGAGEMENTS](context, payload) {
    return ApiService.delete(
      "api/v1/activities/" +
        payload.activity_id +
        "/activity_response_replies/" +
        payload.id
    )
      .then(() => {
        context.commit(REMOVE_ACTIVITY_REPLY_ENGAGEMENTS, payload.id);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CLEAR_REPLY_ENGAGEMENTS_LIST](context) {
    context.commit(RESET_ACTIVITY_REPLY_ENGAGEMENTS_LIST);
  },
  [CLEAR_REPLY_ENGAGEMENTS_INFO](context) {
    context.commit(RESET_ACTIVITY_REPLY_ENGAGEMENTS_INFO);
  },
  [CLEAR_REPLY_ENGAGEMENTS_ERRORS](context) {
    context.commit(RESET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS);
  }
};

const mutations = {
  [SET_ACTIVITY_REPLY_ENGAGEMENTS_LIST](state, data) {
    let response_id = data[0].responseId;
    let index = state.activity_reply_engagements_list.findIndex(
      replies => replies.response_id === response_id
    );
    if (index == -1) {
      let list = data[1];
      state.activity_reply_engagements_list.push({
        list: list,
        response_id: response_id
      });
    }
  },
  [APPEND_ACTIVITY_REPLY_ENGAGEMENTS_LIST](state, data) {
    let response_id = data[0].responseId;
    let index = state.activity_reply_engagements_list.findIndex(
      replies => replies.response_id === response_id
    );
    if (index !== -1) {
      let responses = data[1];
      responses.forEach(function(response) {
        state.activity_reply_engagements_list[index].list.push(response);
      });
    }
  },
  [RESET_ACTIVITY_REPLY_ENGAGEMENTS_LIST](state) {
    state.activity_reply_engagements_list = [];
  },
  [SET_ACTIVITY_REPLY_ENGAGEMENTS_INFO](
    state,
    activity_reply_engagements_info
  ) {
    state.activity_reply_engagements_info = activity_reply_engagements_info;
  },
  [RESET_ACTIVITY_REPLY_ENGAGEMENTS_INFO](state) {
    state.activity_reply_engagements_info = [];
  },
  [ADD_ACTIVITY_REPLY_ENGAGEMENTS](state, activity_reply_engagements_info) {
    let response_id = activity_reply_engagements_info.repliable_id;
    let index = state.activity_reply_engagements_list.findIndex(
      replies => replies.response_id === response_id
    );
    if (index == -1) {
      state.activity_reply_engagements_list.push({
        list: [activity_reply_engagements_info],
        response_id: response_id
      });
    } else {
      state.activity_reply_engagements_list[index].list.push(
        activity_reply_engagements_info
      );
    }
  },
  [UPDATE_ACTIVITY_REPLY_ENGAGEMENTS](state, result) {
    let edited_id = result.id;
    let response_id = result.repliable_id;
    let index = state.activity_reply_engagements_list.findIndex(
      replies => replies.response_id === response_id
    );
    let editedIndex = state.activity_reply_engagements_list[
      index
    ].list.findIndex(replies => replies.id === edited_id);
    state.activity_reply_engagements_list[index].list[editedIndex] = result;
  },
  [REMOVE_ACTIVITY_REPLY_ENGAGEMENTS](state, activityReplyEngagementId) {
    for (
      let index = 0;
      index < state.activity_reply_engagements_list.length;
      index++
    ) {
      const element = state.activity_reply_engagements_list[index].list;
      let toBeRemoved = element.findIndex(
        replies => replies.id === activityReplyEngagementId
      );
      if (toBeRemoved > -1) {
        element.splice(toBeRemoved, 1);
        state.activity_reply_engagements_list[index].list = element;
      }
    }
  },
  [SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS](
    state,
    activity_reply_engagements_errors
  ) {
    state.activity_reply_engagements_errors = activity_reply_engagements_errors;
  },
  [RESET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS](state) {
    state.activity_reply_engagements_errors = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
