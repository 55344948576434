import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_PROJECT_INTRODUCTION = "populateProjectIntroduction";
export const CLEAR_PROJECT_INTRODUCTION = "clearProjectIntroduction";
export const CREATE_PROJECT_INTRODUCTION = "createProjectIntroduction";
export const UPDATE_PROJECT_INTRODUCTION = "updateProjectIntroduction";
export const DELETE_PROJECT_INTRODUCTION = "deleteProjectIntroduction";
export const CLEAR_PROJECT_INTRODUCTION_ERRORS =
  "clearProjectIntroductionErrors";

// mutation types
export const SET_PROJECT_INTRODUCTION = "setProjectIntroduction";
export const RESET_PROJECT_INTRODUCTION = "resetProjectIntroduction";
export const SET_PROJECT_INTRODUCTION_ERRORS = "setProjectIntroductionErrors";
export const RESET_PROJECT_INTRODUCTION_ERRORS =
  "resetProjectIntroductionErrors";

const state = {
  project_introduction: {},
  errors: {}
};

const getters = {
  projectIntroduction(state) {
    return state.project_introduction;
  },
  projectIntroductionErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_INTRODUCTION](context, payload) {
    return ApiService.get(
      "api/v1/projects/" +
        payload.projectId +
        "/project_introductions", { params: payload.params }
    )
      .then(res => {
        const projectIntroduction = res.data.data._embedded.project_introductions;
        if (projectIntroduction.length > 0) {
            context.commit(SET_PROJECT_INTRODUCTION, projectIntroduction[0]);
        }
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_INTRODUCTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_INTRODUCTION](context) {
    context.commit(CLEAR_PROJECT_INTRODUCTION);
  },
  [CREATE_PROJECT_INTRODUCTION](context, payload) {
    return ApiService.post(
      "api/v1/projects/" + payload.projectId + "/project_introductions",
      payload.data
    )
      .then(res => {
        const projectIntroduction = res.data.data;
        context.commit(SET_PROJECT_INTRODUCTION, projectIntroduction);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_INTRODUCTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PROJECT_INTRODUCTION](context, payload) {
    return ApiService.put(
      "api/v1/projects/" +
        payload.projectId +
        "/project_introductions/" +
        payload.id,
      payload.data
    )
      .then(res => {
        context.commit(SET_PROJECT_INTRODUCTION, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_INTRODUCTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PROJECT_INTRODUCTION](context, payload) {
    return ApiService.delete(
      "api/v1/projects/" +
        payload.projectId +
        "/project_introductions/" +
        payload.id
    )
      .then(() => {
        context.commit(CLEAR_PROJECT_INTRODUCTION);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_INTRODUCTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_INTRODUCTION_ERRORS](context) {
    context.commit(CLEAR_PROJECT_INTRODUCTION_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_INTRODUCTION](state, projectIntroduction) {
    state.project_introduction = projectIntroduction;
  },
  [CLEAR_PROJECT_INTRODUCTION](state) {
    state.project_introduction = {};
  },
  [SET_PROJECT_INTRODUCTION_ERRORS](state, errors) {
    state.errors = errors;
  },
  [CLEAR_PROJECT_INTRODUCTION_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
