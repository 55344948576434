import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_SYSTEM_LANGUAGES = "populateSystemLanguages";
export const POPULATE_USER_LANGUAGE = "populateUserLanguage";
export const POPULATE_DEFAULT_LANGUAGE = "populateDefaultLanguage";

// mutation types
export const SET_SYSTEM_LANGUAGES = "setSystemLanguages";
export const SET_USER_LANGUAGE = "setUserLanguage";

const state = {
  system_languages: [],
  user_language: {}
};

const getters = {
  supportedLanguages(state) {
    return state.system_languages;
  },
  userLanguage(state) {
    return state.user_language;
  }
};

const actions = {
  [POPULATE_SYSTEM_LANGUAGES](context) {
    return ApiService.get("api/v1/system_languages", {
      params: {
        paginate: false
      }
    })
      .then(res => {
        const languages = res.data.data._embedded.system_languages;
        context.commit(SET_SYSTEM_LANGUAGES, languages);
      })
      .catch(error => {
        Vue.$log.error(error);
        throw error;
      });
  },
  [POPULATE_USER_LANGUAGE](context, payload) {
    return ApiService.put("api/v1/users/" + payload.userId, {
      system_language_code: payload.langaugeCode,
      display_name: payload.display_name,
      name: payload.name
    })
    .then(res => {
      const language = res.data.data.language;
      context.commit(SET_USER_LANGUAGE, language);
    })
    .catch(error => {
      Vue.$log.error(error);
      throw error;
    });
  },
  [POPULATE_DEFAULT_LANGUAGE](context, code) {
    return ApiService.get("api/v1/system_languages/" + code)
      .then(res => {
        const language = res.data.data;
        context.commit(SET_USER_LANGUAGE, language);
      })
      .catch(error => {
        Vue.$log.error(error);
        throw error;
      });
  }
};

const mutations = {
  [SET_SYSTEM_LANGUAGES](state, languages) {
    state.system_languages = languages;
  },
  [SET_USER_LANGUAGE](state, language) {
    state.user_language = language;
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};