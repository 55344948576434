import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_EXTENDED_PROFILE_QUESTIONS = "populateExtendedProfileQuestions";
export const POPULATE_EXTENDED_PROFILE_QUESTION = "populateExtendedProfileQuestion";
export const CLEAR_EXTENDED_PROFILE_QUESTIONS = "clearExtendedProfileQuestions";
export const CLEAR_EXTENDED_PROFILE_QUESTION = "clearExtendedProfileQuestion";
export const CREATE_EXTENDED_PROFILE_QUESTION = "createExtendedProfileQuestion";
export const UPDATE_EXTENDED_PROFILE_QUESTION = "updateExtendedProfileQuestion";
export const DELETE_EXTENDED_PROFILE_QUESTION = "deleteExtendedProfileQuestion";
export const CLEAR_EXTENDED_PROFILE_QUESTION_ERRORS = "clearExtendedProfileQuestionErrors";

// mutation types
export const SET_EXTENDED_PROFILE_QUESTIONS = "setExtendedProfileQuestions";
export const RESET_EXTENDED_PROFILE_QUESTIONS = "resetExtendedProfileQuestions";
export const SET_EXTENDED_PROFILE_QUESTION = "setExtendedProfileQuestion";
export const RESET_EXTENDED_PROFILE_QUESTION = "resetExtendedProfileQuestion";
export const SET_EXTENDED_PROFILE_QUESTION_ERRORS = "setExtendedProfileQuestionErrors";
export const RESET_EXTENDED_PROFILE_QUESTION_ERRORS = "resetExtendedProfileQuestionErrors";
export const REMOVE_EXTENDED_PROFILE_QUESTION = "removeExtendedProfileQuestion";

const state = {
  extended_profile_questions: [],
  extended_profile_question: {},
  errors: {}
};

const getters = {
  extendedProfileQuestions(state) {
    return state.extended_profile_questions;
  },
  extendedProfileQuestion(state) {
    return state.extended_profile_question;
  },
  extendedProfileQuestionErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_EXTENDED_PROFILE_QUESTIONS](context, payload) {
    return ApiService.get(
      "api/v1/projects/" +
        payload.projectId +
        "/extended_profile_questions", {
          params: payload.params
        }
    )
    .then(res => {
      const extendedProfileQuestions = res.data.data._embedded.extended_profile_questions;
      context.commit(SET_EXTENDED_PROFILE_QUESTIONS, extendedProfileQuestions);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_EXTENDED_PROFILE_QUESTION_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [POPULATE_EXTENDED_PROFILE_QUESTION](context, payload) {
    return ApiService.get(
      "api/v1/projects/" +
        payload.projectId +
        "/extended_profile_questions/" +
        payload.id, {
          params: payload.params
        }
    )
    .then(res => {
      const extendedProfileQuestion = res.data.data;
      context.commit(SET_EXTENDED_PROFILE_QUESTION, extendedProfileQuestion);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_EXTENDED_PROFILE_QUESTION_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [CLEAR_EXTENDED_PROFILE_QUESTIONS](context) {
    context.commit(RESET_EXTENDED_PROFILE_QUESTIONS);
  },
  [CLEAR_EXTENDED_PROFILE_QUESTION](context) {
    context.commit(RESET_EXTENDED_PROFILE_QUESTION);
  },
  [CREATE_EXTENDED_PROFILE_QUESTION](context, payload) {
    return ApiService.post(
      "api/v1/projects/" + payload.projectId + "/extended_profile_questions",
      payload.data
    )
    .then(res => {
      const extendedProfileQuestion = res.data.data;
      context.commit(SET_EXTENDED_PROFILE_QUESTION, extendedProfileQuestion);
      return extendedProfileQuestion;
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_EXTENDED_PROFILE_QUESTION_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [UPDATE_EXTENDED_PROFILE_QUESTION](context, payload) {
    return ApiService.put(
      "api/v1/projects/" +
        payload.projectId +
        "/extended_profile_questions/" +
        payload.id,
      payload.data
    )
    .then(res => {
      const extendedProfileQuestion = res.data.data;
      context.commit(SET_EXTENDED_PROFILE_QUESTION, extendedProfileQuestion);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_EXTENDED_PROFILE_QUESTION_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [DELETE_EXTENDED_PROFILE_QUESTION](context, payload) {
    return ApiService.delete(
      "api/v1/projects/" +
        payload.projectId +
        "/extended_profile_questions/" +
        payload.id
    )
    .then(() => {
      context.commit(REMOVE_EXTENDED_PROFILE_QUESTION, payload.id);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_EXTENDED_PROFILE_QUESTION_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [CLEAR_EXTENDED_PROFILE_QUESTION_ERRORS](context) {
    context.commit(RESET_EXTENDED_PROFILE_QUESTION_ERRORS);
  }
};

const mutations = {
  [SET_EXTENDED_PROFILE_QUESTIONS](state, extendedProfileQuestions) {
    state.extended_profile_questions = extendedProfileQuestions;
  },
  [RESET_EXTENDED_PROFILE_QUESTIONS](state) {
    state.extended_profile_questions = [];
  },
  [SET_EXTENDED_PROFILE_QUESTION](state, extendedProfileQuestion) {
    state.extended_profile_question = extendedProfileQuestion;
  },
  [RESET_EXTENDED_PROFILE_QUESTION](state) {
    state.extended_profile_question = {};
  },
  [SET_EXTENDED_PROFILE_QUESTION_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_EXTENDED_PROFILE_QUESTION_ERRORS](state) {
    state.errors = {};
  },
  [REMOVE_EXTENDED_PROFILE_QUESTION](state, extendedProfileQuestionId) {
    state.extended_profile_questions = state.extended_profile_questions.filter(function( obj ) {
      return obj.id !== extendedProfileQuestionId;
    });
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
