import ApiService from "@/core/services/api.service";
import Vue from "vue";
const { unionBy } = require("lodash/array");

// action types
export const POPULATE_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST =
  "populateActivityPictureBookRepsonsesList";
export const POPULATE_ACTIVITY_PICTURE_BOOK_RESPONSES_INFO =
  "populateActivityPictureBookRepsonsesInfo";
export const POPULATE_ACTIVITY_ADDITIONAL_PICTURE_BOOK_RESPONSES_INFO =
  "populateActivityAdditionalPictureBookRepsonsesInfo";
export const CREATE_PICTURE_BOOK_RESPONSES = "createPictureBookRepsonses";
export const UPDATE_PICTURE_BOOK_RESPONSES = "updatePictureBookRepsonses";
export const DELETE_PICTURE_BOOK_RESPONSES = "deletePictureBookRepsonses";
export const CLEAR_PICTURE_BOOK_RESPONSES_LIST =
  "clearPictureBookRepsonsesList";
export const CLEAR_PICTURE_BOOK_RESPONSES_INFO =
  "clearPictureBookRepsonsesInfo";
export const CLEAR_PICTURE_BOOK_RESPONSES_ERRORS =
  "clearPictureBookRepsonsesErrors";

// mutation types
export const SET_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST =
  "setActivityPictureBookRepsonsesList";
export const RESET_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST =
  "resetActivityPictureBookRepsonsesList";
export const SET_ACTIVITY_PICTURE_BOOK_RESPONSES_INFO =
  "setActivityPictureBookRepsonsesInfo";
export const RESET_ACTIVITY_PICTURE_BOOK_RESPONSES_INFO =
  "resetActivityPictureBookRepsonsesInfo";
export const SET_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST_META = "setActivityPictureBookRepsonsesMeta";
export const RESET_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST_META = "resetActivityPictureBookRepsonsesMeta";
export const APPEND_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST =
  "appendActivityPictureBookRepsonsesInfo";
export const ADD_ACTIVITY_PICTURE_BOOK_RESPONSES =
  "addActivityPictureBookRepsonses";
export const UPDATE_ACTIVITY_PICTURE_BOOK_RESPONSES =
  "updateActivityPictureBookRepsonses";
export const REMOVE_ACTIVITY_PICTURE_BOOK_RESPONSES =
  "removeActivityPictureBookRepsonses";
export const SET_ACTIVITY_PICTURE_BOOK_RESPONSES_ERRORS =
  "setActivityPictureBookRepsonsesErrors";
export const RESET_ACTIVITY_PICTURE_BOOK_RESPONSES_ERRORS =
  "resetActivityPictureBookRepsonsesErrors";

const state = {
  activity_picture_book_responses_errors: {},
  activity_picture_book_responses_list: [],
  activity_picture_book_responses_list_meta: {},
  activity_picture_book_responses_info: []
};

const getters = {
  activityPictureBookRepsonsesErrors(state) {
    return state.activity_picture_book_responses_errors;
  },
  activityPictureBookRepsonsesList(state) {
    return state.activity_picture_book_responses_list;
  },
  activityPictureBookRepsonsesListMeta(state) {
    return state.activity_picture_book_responses_list_meta;
  },
  activityPictureBookRepsonsesInfo(state) {
    return state.activity_picture_book_responses_info;
  }
};

const actions = {
  [POPULATE_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_picture_book_responses",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses =
          res.data.data._embedded.activity_picture_book_responses;
        context.commit(SET_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST, responses);
        if(res.data.meta != undefined) {
          context.commit(SET_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST_META, res.data.meta);
        }
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_PICTURE_BOOK_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_PICTURE_BOOK_RESPONSES_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_picture_book_responses/" +
        payload.activityPictureBookResponseId,
      {
        params: payload.params
      }
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_PICTURE_BOOK_RESPONSES_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_PICTURE_BOOK_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_ADDITIONAL_PICTURE_BOOK_RESPONSES_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_picture_book_responses",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses =
          res.data.data._embedded.activity_picture_book_responses;
        context.commit(
          APPEND_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST,
          payload,
          responses
        );
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_PICTURE_BOOK_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_PICTURE_BOOK_RESPONSES](context, payload) {
    return ApiService.post(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_picture_book_responses",
      payload.data
    )
      .then(res => {
        context.commit(ADD_ACTIVITY_PICTURE_BOOK_RESPONSES, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_PICTURE_BOOK_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [UPDATE_PICTURE_BOOK_RESPONSES](context, payload) {
    return ApiService.put(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_picture_book_responses/" +
        payload.activityPictureBookResponseId,
      payload.data
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_PICTURE_BOOK_RESPONSES_INFO, response);
        context.commit(UPDATE_ACTIVITY_PICTURE_BOOK_RESPONSES, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_PICTURE_BOOK_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [DELETE_PICTURE_BOOK_RESPONSES](context, payload) {
    return ApiService.delete(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_picture_book_responses/" +
        payload.activityPictureBookResponseId
    )
      .then(res => {
        Vue.$log.info("deleted");
        Vue.$log.info(res);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_PICTURE_BOOK_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CLEAR_PICTURE_BOOK_RESPONSES_LIST](context) {
    context.commit(RESET_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST);
  },
  [CLEAR_PICTURE_BOOK_RESPONSES_INFO](context) {
    context.commit(RESET_ACTIVITY_PICTURE_BOOK_RESPONSES_INFO);
  },
  [CLEAR_PICTURE_BOOK_RESPONSES_ERRORS](context) {
    context.commit(RESET_ACTIVITY_PICTURE_BOOK_RESPONSES_ERRORS);
  }
};

const mutations = {
  [SET_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST](state, activity_picture_book_responses_list) {
    if (state.activity_picture_book_responses_list.length !== 0) {
      state.activity_picture_book_responses_list = unionBy(state.activity_picture_book_responses_list, activity_picture_book_responses_list, "id");
    } else {
      state.activity_picture_book_responses_list = activity_picture_book_responses_list;
    }
  },
  [RESET_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST](state) {
    state.activity_picture_book_responses_list = [];
  },
  [SET_ACTIVITY_PICTURE_BOOK_RESPONSES_INFO](state, activity_picture_book_responses_info) {
    state.activity_picture_book_responses_info = activity_picture_book_responses_info;
  },
  [RESET_ACTIVITY_PICTURE_BOOK_RESPONSES_INFO](state) {
    state.activity_picture_book_responses_info = [];
  },

  [SET_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST_META](state, activity_picture_book_responses_list_meta) {
    state.activity_picture_book_responses_list_meta = activity_picture_book_responses_list_meta;
  },
  [RESET_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST_META](state) {
    state.activity_picture_book_responses_list_meta = {};
  },
  [ADD_ACTIVITY_PICTURE_BOOK_RESPONSES](state, activity_picture_book_responses_info) {
    state.activity_picture_book_responses_list.push(activity_picture_book_responses_info);
  },
  [UPDATE_ACTIVITY_PICTURE_BOOK_RESPONSES](state, activity_picture_book_responses_info) {
    let newActivityPictureBookRepsonsesArray = [];
    newActivityPictureBookRepsonsesArray.push(activity_picture_book_responses_info);
    state.activity_picture_book_responses_list = state.activity_picture_book_responses_list.map((obj) => newActivityPictureBookRepsonsesArray.find((o) => o.id === obj.id) || obj);
  },
  [APPEND_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST](state, payload, activity_picture_book_responses_additional_list) {
    let responseToIndex = state.activity_picture_book_responses_list.findIndex((p) => p.id == payload.responding_to); // to be update
    if (responseToIndex !== -1) {
      state.activity_picture_book_responses_list = state.activity_picture_book_responses_list.splice(responseToIndex, 0, activity_picture_book_responses_additional_list);
    } else {
      state.activity_picture_book_responses_list.push(activity_picture_book_responses_additional_list);
    }
  },
  [REMOVE_ACTIVITY_PICTURE_BOOK_RESPONSES](state, activityPictureBookResponseId) {
    state.activity_picture_book_responses_list = state.activity_picture_book_responses_list.filter(function (obj) {
      return obj.id !== activityPictureBookResponseId;
    });
  },
  [SET_ACTIVITY_PICTURE_BOOK_RESPONSES_ERRORS](state, activity_picture_book_responses_errors) {
    state.activity_picture_book_responses_errors = activity_picture_book_responses_errors;
  },
  [RESET_ACTIVITY_PICTURE_BOOK_RESPONSES_ERRORS](state) {
    state.activity_picture_book_responses_errors = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
