import ApiService from "@/core/services/api.service";

import Vue from "vue";

// XML TO JSON
import X2JS from "x2js";
// action types
export const POPULATE_PAGE_LIST = "populatePageList";
export const POPULATE_PAGE_INFO = "populatePageInfo";
export const POPULATE_PAGE_SECTION_INFO = "populatePageSectionInfo";
export const POPULATE_PAGE_RSS_FEED = "populatePageSectionInfoRssFeed";
export const CLEAR_PAGE_LIST = "clearPageList";
export const CLEAR_PAGE_INFO = "clearPageInfo";
export const CLEAR_PAGE_ERRORS = "clearProjectErrors";

// mutation types
export const SET_PAGE_LIST = "setPageList";
export const RESET_PAGE_LIST = "resetPageList";
export const SET_PAGE_INFO = "setPageInfo";
export const SET_PAGE_RSS_FEED = "setPageRssInfo";
export const SET_PAGE_SECTION_INFO = "setPageSectionInfo";
export const RESET_PAGE_INFO = "resetPageInfo";
export const SET_PAGE_ERRORS = "setPageErrors";
export const SET_PAGE_SECTION_ERRORS = "setPageSectionErrors";
export const RESET_PAGE_ERRORS = "resetPageErrors";

const state = {
  page_errors: {},
  page_list: [],
  page_info: [],
  page_section_info: [],
  page_section_errors: [],
  page_rss_feed: []
};

const getters = {
  pageErrors(state) {
    return state.errors;
  },
  pageList(state) {
    return state.page_list;
  },
  pageInfo(state) {
    return state.page_info;
  },
  pageSectionInfo(state) {
    return state.page_section_info;
  },
  pageSectionErrors(state) {
    return state.page_section_errors;
  },
  pageRssFeed(state) {
    return state.page_rss_feed;
  }
};

const actions = {
  [POPULATE_PAGE_LIST](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/pages", {
      params: payload.params
    })
      .then(res => {
        const pages = res.data.data._embedded.pages;
        context.commit(SET_PAGE_LIST, pages);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_PAGE_INFO](context, payload) {
    return ApiService.get(
      "api/v1/projects/" + payload.projectId + "/pages/" + payload.pageId,
      {
        params: payload.params
      }
    )
      .then(res => {
        const page = res.data.data;
        context.commit(SET_PAGE_INFO, page);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_PAGE_SECTION_INFO](context, payload) {
    return ApiService.get(
      "api/v1/pages/" +
        payload.pageId +
        "/page_sections/" +
        payload.pageSectionId,
      {
        params: payload.params
      }
    )
      .then(res => {
        const page_section = res.data.data;
        context.commit(SET_PAGE_SECTION_INFO, page_section);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_SECTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_PAGE_RSS_FEED](context, payload) {
    return ApiService.get(
      "api/v1/projects/" + payload.projectId + "/pages/" + payload.pageId,
      {
        params: { rss: "true" }
      }
    )
      .then(res => {
        var x2js = new X2JS();
        var json = x2js.xml2js(res.data);
        context.commit(SET_PAGE_RSS_FEED, json);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_SECTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CLEAR_PAGE_LIST](context) {
    context.commit(RESET_PAGE_LIST);
  },
  [CLEAR_PAGE_INFO](context) {
    context.commit(RESET_PAGE_INFO);
  },
  [CLEAR_PAGE_ERRORS](context) {
    context.commit(RESET_PAGE_ERRORS);
  }
};

const mutations = {
  [SET_PAGE_LIST](state, pages) {
    state.page_list = pages;
  },
  [RESET_PAGE_LIST](state) {
    state.page_list = [];
  },
  [SET_PAGE_INFO](state, page) {
    state.page_info = page;
  },
  [SET_PAGE_SECTION_INFO](state, page_section) {
    state.page_section_info = page_section;
  },
  [SET_PAGE_RSS_FEED](state, page_rss_feed) {
    state.page_rss_feed = page_rss_feed;
  },
  [RESET_PAGE_INFO](state) {
    state.page_info = [];
  },
  [SET_PAGE_ERRORS](state, errors) {
    state.page_errors = errors;
  },
  [SET_PAGE_SECTION_ERRORS](state, errors) {
    state.page_section_errors = errors;
  },
  [RESET_PAGE_ERRORS](state) {
    state.page_errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
