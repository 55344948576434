import VuexHelpers from "../../helpers/VuexHelpers";

// action types
export const CREATE_PROJECT_FORUM         = "createProjectForum";
export const UPDATE_PROJECT_FORUM         = "updateProjectForum";
export const DELETE_PROJECT_FORUM         = "deleteProjectForum";

// mutation types
export const SET_PROJECT_FORUM_LIST       = "setProjectForumList";
export const SET_PROJECT_FORUM_INFO       = "setProjectForumInfo";
export const SET_PROJECT_FORUM_ERRORS     = "setProjectForumErrors";
export const ADD_PROJECT_FORUM            = "addProjectForum";
export const RESET_PROJECT_FORUM          = "resetProjectForum";
export const STRIP_PROJECT_FORUM          = "stripProjectForum";

const state = {
  project_forum_list: [],
  project_forum_info: null,
  project_forum_errors: null,
};

const getters = {
  projectForumList(state) {
    return state.project_forum_list;
  },
  projectForumInfo(state) {
    return state.project_forum_info;
  },
  projectForumErrors(state) {
    return state.project_forum_errors;
  }
};

const actions = {
  [CREATE_PROJECT_FORUM](context, payload) {
    let url = payload.url;
    return VuexHelpers.create("ProjectForum", context, url, payload);
  },
  [UPDATE_PROJECT_FORUM](context, payload) {
    let url = `api/v1/projects/${payload.projectId}/forums`;
    return VuexHelpers.create("ProjectForum", context, url, payload, false);
  },
  [DELETE_PROJECT_FORUM](context, payload) {
    let url = payload.url;
    return VuexHelpers.delete("ProjectForum", context, url, payload.projectForumId);
  },
}

const mutations = {
  [SET_PROJECT_FORUM_LIST](state, project_forum_list) {
    state.project_forum_list = project_forum_list;
  },
  [SET_PROJECT_FORUM_INFO](state, project_forum_info) {
    state.project_forum_info = project_forum_info;
  },
  [SET_PROJECT_FORUM_ERRORS](state, errors = null) {
    state.project_forum_errors = errors;
  },
  [ADD_PROJECT_FORUM](state, project_forum_info) {
    state.project_forum_list.push(project_forum_info);
  },
  [RESET_PROJECT_FORUM](state, updatedProjectForum) {
    VuexHelpers.reset(state, 'project_forum', updatedProjectForum);
  },
  [STRIP_PROJECT_FORUM](state, removedProjectForumId) {
    VuexHelpers.strip(state, 'project_forum', removedProjectForumId);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};