import ApiService from "@/core/services/api.service";
import VuexHelpers from "../../helpers/VuexHelpers";

// action types
export const CREATE_PROJECT_FORUM_RESPONSE_REPLY = "createProjectForumResponseReply";
export const UPDATE_PROJECT_FORUM_RESPONSE_REPLY = "updateProjectForumResponseReply";
export const LOAD_MORE_PROJECT_FORUM_RESPONSE_REPLY = "fetchProjectForumResponseReply";
export const CLEAR_PROJECT_FORUM_RESPONSE_REPLY_LIST = "clearProjectForumResponseReply";
export const DELETE_PROJECT_FORUM_RESPONSE_REPLY = "deleteProjectForumResponseReply";

// mutation types
export const SET_PROJECT_FORUM_RESPONSE_REPLY_LIST = "setProjectForumResponseReplyList";
export const RESET_PROJECT_FORUM_RESPONSE_REPLY_LIST = "resetProjectForumResponseReplyList";
export const APPEND_PROJECT_FORUM_RESPONSE_REPLY_LIST = "appendProjectForumResponseReplyList";
export const SET_PROJECT_FORUM_RESPONSE_REPLY_INFO = "setProjectForumResponseReplyInfo";
export const SET_PROJECT_FORUM_RESPONSE_REPLY_ERRORS = "setProjectForumResponseReplyErrors";
export const ADD_PROJECT_FORUM_RESPONSE_REPLY = "addProjectForumResponseReply";
export const STRIP_PROJECT_FORUM_RESPONSE_REPLY = "stripProjectForumResponseReply";

const state = {
  project_forum_response_reply_list: [],
  project_forum_response_reply_info: null,
  project_forum_response_reply_errors: null
};

const getters = {
  projectForumResponseReplyList(state) {
    return state.project_forum_response_reply_list;
  },
  projectForumResponseReplyInfo(state) {
    return state.project_forum_response_reply_info;
  },
  projectForumResponseReplyErrors(state) {
    return state.project_forum_response_reply_errors;
  }
};

const actions = {
  [CREATE_PROJECT_FORUM_RESPONSE_REPLY](context, payload) {
    let url = `api/v1/projects/${payload.projectId}/forums/${payload.projectForumId}/forum_response_replies`;
    return VuexHelpers.create("ProjectForumResponseReply", context, url, payload);
  },
  [UPDATE_PROJECT_FORUM_RESPONSE_REPLY](context, payload) {
    let url = `api/v1/projects/${payload.projectId}/forums/${payload.projectForumId}/forum_response_replies/${payload.responseId}`;
    return VuexHelpers.update("ProjectForumResponseReply", context, url, payload, false);
  },
  [LOAD_MORE_PROJECT_FORUM_RESPONSE_REPLY](context, payload) {
    let url = `api/v1/projects/${payload.projectId}/forums/${payload.projectForumId}/forum_response_replies`;
    return ApiService.get(url, {
      params: payload.params
    })
      .then((res) => {
        const responses = res.data.data._embedded.forum_response_replies;
        context.commit(APPEND_PROJECT_FORUM_RESPONSE_REPLY_LIST, [payload, responses]);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_PROJECT_FORUM_RESPONSE_REPLY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [DELETE_PROJECT_FORUM_RESPONSE_REPLY](context, payload) {
    let url = `api/v1/projects/${payload.projectId}/forums/${payload.projectForumId}/forum_response_replies/${payload.id}`;
    return VuexHelpers.delete("ProjectForumResponseReply", context, url, payload.id);
  },
  [CLEAR_PROJECT_FORUM_RESPONSE_REPLY_LIST](context) {
    context.commit(RESET_PROJECT_FORUM_RESPONSE_REPLY_LIST);
  }
};

const mutations = {
  [SET_PROJECT_FORUM_RESPONSE_REPLY_LIST](state, project_forum_response_reply_list) {
    state.project_forum_response_reply_list = project_forum_response_reply_list;
  },
  [RESET_PROJECT_FORUM_RESPONSE_REPLY_LIST](state) {
    state.project_forum_response_reply_list = [];
  },
  [SET_PROJECT_FORUM_RESPONSE_REPLY_INFO](state, project_forum_response_reply_info) {
    state.project_forum_response_reply_info = project_forum_response_reply_info;
  },
  [APPEND_PROJECT_FORUM_RESPONSE_REPLY_LIST](state, data) {
    let response_id = data[0].responseId;
    let index = state.project_forum_response_reply_list.findIndex((replies) => replies.response_id === response_id);
    if (index !== -1) {
      let responses = data[1];
      responses.forEach(function (response) {
        state.project_forum_response_reply_list[index].list.push(response);
      });
    } else {
      let list = data[1];
      state.project_forum_response_reply_list.push({
        list: list,
        response_id: response_id
      });
    }
  },
  [SET_PROJECT_FORUM_RESPONSE_REPLY_ERRORS](state, errors = null) {
    state.project_forum_response_reply_errors = errors;
  },
  [ADD_PROJECT_FORUM_RESPONSE_REPLY](state, project_forum_response_reply_info) {
    state.project_forum_response_reply_list.push(project_forum_response_reply_info);
  },
  [STRIP_PROJECT_FORUM_RESPONSE_REPLY](state, id) {
    VuexHelpers.strip(state, "project_forum_response_reply", id);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
