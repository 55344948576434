import VuexHelpers from "../../helpers/VuexHelpers";

// action types
export const CREATE_PROJECT_FORUM_RESPONSE         = "createProjectForumResponse";
export const UPDATE_PROJECT_FORUM_RESPONSE         = "updateProjectForumResponse";
export const DELETE_PROJECT_FORUM_RESPONSE         = "deleteProjectForumResponse";

// mutation types
export const SET_PROJECT_FORUM_RESPONSE_LIST       = "setProjectForumResponseList";
export const SET_PROJECT_FORUM_RESPONSE_INFO       = "setProjectForumResponseInfo";
export const SET_PROJECT_FORUM_RESPONSE_ERRORS     = "setProjectForumResponseErrors";
export const ADD_PROJECT_FORUM_RESPONSE            = "addProjectForumResponse";
export const STRIP_PROJECT_FORUM_RESPONSE          = "stripProjectForumResponse";

const state = {
  project_forum_response_list: [],
  project_forum_response_info: null,
  project_forum_response_errors: null,
};

const getters = {
  projectForumResponseList(state) {
    return state.project_forum_response_list;
  },
  projectForumResponseInfo(state) {
    return state.project_forum_response_info;
  },
  projectForumResponseErrors(state) {
    return state.project_forum_response_errors;
  }
};

const actions = {
  [CREATE_PROJECT_FORUM_RESPONSE](context, payload) {
    let url = `api/v1/projects/${payload.projectId}/forums/${payload.projectForumId}/forum_responses`;
    return VuexHelpers.create("ProjectForumResponse", context, url, payload);
  },
  [UPDATE_PROJECT_FORUM_RESPONSE](context, payload) {
    let url = `api/v1/projects/${payload.projectId}/forums/${payload.projectForumId}/forum_responses/${payload.responseId}`;
    return VuexHelpers.update("ProjectForumResponse", context, url, payload, false);
  },
  [DELETE_PROJECT_FORUM_RESPONSE](context, payload) {
    let url = `api/v1/projects/${payload.projectId}/forums/${payload.projectForumId}/forum_responses/${payload.id}`;
    return VuexHelpers.delete("ProjectForumResponse", context, url, payload.id);
  }
};

const mutations = {
  [SET_PROJECT_FORUM_RESPONSE_LIST](state, project_forum_response_list) {
    state.project_forum_response_list = project_forum_response_list;
  },
  [SET_PROJECT_FORUM_RESPONSE_INFO](state, project_forum_response_info) {
    state.project_forum_response_info = project_forum_response_info;
  },
  [SET_PROJECT_FORUM_RESPONSE_ERRORS](state, errors = null) {
    state.project_forum_response_errors = errors;
  },
  [ADD_PROJECT_FORUM_RESPONSE](state, project_forum_response_info) {
    state.project_forum_response_list.push(project_forum_response_info);
  },
  [STRIP_PROJECT_FORUM_RESPONSE](state, id) {
    VuexHelpers.strip(state, 'project_forum_response', id);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};