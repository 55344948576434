<template>
  <a
    href="javascript:void(0)"
    @click="toggleReply"
    class="btn btn-icon-success btn-sm btn-text-dark-50 btn-hover-text-success rounded font-weight-bolder font-size-xs p-2"
    :class="{ 'bg-hover-light-success' : !replyState.showReplies, 'bg-light-success' : replyState.showReplies }"
    v-b-tooltip.hover.bottom
    :title="tooltip"
  >
    <span class="svg-icon svg-icon-md svg-icon-danger pr-1">
      <i
        class="fa-comment-alt text-success fa-lg"
        :class="{ 'far' : !iHaveEngaged, 'fas' : iHaveEngaged > 0 }"
      >
      </i>
    </span>
    {{ totalReplies }}
  </a>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EngagementReply",

  props: {
    activityId: { type: Number, required: true },
    replies: { type: Array, required: true },
    replyState: { type: Object, required: true },
    legacy: { type: Boolean, required: false, default: false }
  },

  methods: {
    toggleReply: function() {
      if (this.totalReplies == 0 && this.currentUser.relation_to_project == 'observer') {
        return;
      } else if (this.totalReplies > 0) {
        this.$emit('toggle_replies');
      } else {
        if (this.replyState.showReplyInput || this.legacy) {
          this.$emit('toggle_replies');
        } else {
          this.$emit('toggle_reply_input');
        }
      }
    }
  },

  computed: {
    ...mapGetters(["currentUser"]),

    iHaveEngaged: function() {
      let currentUser = this.currentUser;

      let myReplies = this.replies.filter(function( reply ) {
        return reply.user_id == currentUser.id;
      });

      return (myReplies.length > 0) ? true : false;
    },

    totalReplies: function() {
      return this.replies.length;
    },

    tooltip: function() {
      let tooltip = null;

      if (this.iHaveEngaged) {
        return `${this.$t("REVIEW.STREAM.REPLY_SUMMARY_ME")}`;
      } else if (this.totalReplies == 1) {
        return this.totalReplies + `${this.$t("REVIEW.STREAM.REPLY_SUMMARY_SINGULAR")}`;
      } else if (this.totalReplies > 1 || this.totalReplies == 0) {
        return this.totalReplies + `${this.$t("REVIEW.STREAM.REPLY_SUMMARY_PLURAL")}`;
      };

      return tooltip;
    }
  }
}
</script>