import ApiService from "@/core/services/api.service";
import Vue from "vue";

//action types
export const POPULATE_PROJECT_WIDGET_LIST = "populateProjectWidgetList";
export const POPULATE_CALENDAR_WIDGET_INFO = "populateCalendarWidgetInfo";
export const POPULATE_MEMBER_ONLINE_WIDGET_INFO =
  "populateMemberOnlineWidgetInfo";
export const POPULATE_RECENT_ACTIVITY_WIDGET_INFO =
  "populateRecentActivityInfo";
export const POPULATE_COMMUNITY_MANAGER_WIDGET_INFO =
  "populateCommunityManagerInfo";
export const POPULATE_POLL_WIDGET_INFO = "populatePollInfo";
export const CLEAR_PROJECT_WIDGET_LIST = "clearProjectWidgetList";
export const CLEAR_PROJECT_WIDGET_ERRORS = "clearProjectWidgetErrors";
export const CLEAR_CALENDAR_WIDGET_ERRORS = "clearCalendarWidgetErrors";
export const CLEAR_POLL_WIDGET_ERRORS = "clearPollWidgetErrors";
export const CLEAR_POLL_WIDGET_INFO = "clearPollWidgetInfo";
export const CLEAR_MEMBER_ONLINE_WIDGET_ERRORS =
  "clearMemberOnlineWidgetErrors";
export const CLEAR_RECENT_ACTIVITY_WIDGET_ERRORS =
  "clearRecentActivityWidgetErrors";
export const CLEAR_COMMUNITY_MANAGER_WIDGET_ERRORS =
  "clearCommunityManagerErrors";

// mutation types
export const SET_PROJECT_WIDGET_LIST = "setProjectWidgetList";
export const RESET_PROJECT_WIDGET_LIST = "resetProjectWidgetList";
export const SET_CALENDAR_WIDGET_INFO = "setCalendarWidgetInfo";
export const SET_POLL_WIDGET_INFO = "setPollWidgetInfo";
export const SET_MEMBER_ONLINE_WIDGET_INFO = "setMemberOnlineWidgetInfo";
export const SET_RECENT_ACTIVITY_WIDGET_INFO = "setRecentActivityWidgetInfo";
export const SET_COMMUNITY_MANAGER_WIDGET_INFO = "setCommunityManagerInfo";
export const RESET_CALENDAR_WIDGET_INFO = "resetCalendarWidgetInfo";
export const RESET_MEMBER_ONLINE_WIDGET_INFO = "resetMemberOnlineWidgetInfo";
export const RESET_RECENT_ACTIVITY_WIDGET_INFO =
  "resetRecentActivityWidgetInfo";
export const RESET_POLL_WIDGET_INFO = "resetPollActivityWidgetInfo";
export const RESET_COMMUNITY_MANAGER_WIDGET_INFO = "resetCommunityManagerInfo";
export const SET_CALENDAR_WIDGET_ERRORS = "setCalendarWidgetErrors";
export const SET_POLL_WIDGET_ERRORS = "setPollWidgetErrors";
export const SET_MEMBER_ONLINE_WIDGET_ERRORS = "setMemberOnlineWidgetErrors";
export const SET_RECENT_ACTIVITY_WIDGET_ERRORS =
  "setRecentActivityWidgetErrors";
export const SET_COMMUNITY_MANAGER_WIDGET_ERRORS = "setCommunityManagerErrors";
export const SET_PROJECT_WIDGET_ERRORS = "setProjectWidgetErrors";
export const RESET_CALENDAR_WIDGET_ERRORS = "resetCalendarWidgetErrors";
export const RESET_POLL_WIDGET_ERRORS = "resetPollWidgetErrors";
export const RESET_MEMBER_ONLINE_WIDGET_ERRORS =
  "resetMemberOnlineWidgetErrors";
export const RESET_RECENT_ACTIVITY_WIDGET_ERRORS =
  "resetRecentActivityWidgetErrors";
export const RESET_COMMUNITY_MANAGER_WIDGET_ERRORS =
  "resetCommunityManagerErrors";
export const RESET_PROJECT_WIDGET_ERRORS = "resetProjectWidgetErrors";

const state = {
  project_widget_list: [], // separated for home and activites
  calendar_widget_info: [], // only one for each project
  member_online_widget_info: [], // only one for each project
  recent_activity_widget_info: [], // only one for each project
  community_manager_widget_info: [], // only one for each project
  poll_widget_info: [], // multiple
  calendar_widget_errors: {},
  member_online_widget_errors: {},
  recent_activity_widget_errors: {},
  community_manager_widget_errors: {},
  poll_widget_errors: {},
  errors: {}
};

const getters = {
  projectWidgets(state) {
    return state.project_widget_list;
  },
  calendarWidgetInfo(state) {
    return state.calendar_widget_info;
  },
  pollWidgetInfo(state) {
    return state.poll_widget_info;
  },
  recentActivityWidgetInfo(state) {
    return state.recent_activity_widget_info;
  },
  communityManagerWidgetInfo(state) {
    return state.community_manager_widget_info;
  },
  memberOnlineWidgetInfo(state) {
    return state.member_online_widget_info;
  },
  calendarWidgetError(state) {
    return state.calendar_widget_errors;
  },
  pollWidgetError(state) {
    return state.poll_widget_errors;
  },
  memberOnlineWidgetError(state) {
    return state.member_online_widget_errors;
  },
  recentActivityWidgetError(state) {
    return state.recent_activity_widget_errors;
  },
  communityManagerWidgetError(state) {
    return state.community_manager_widget_errors;
  },
  projectWidgetErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_WIDGET_LIST](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/project_widgets", { params: payload.params })
      .then((res) => {
        const projectWidgets = res.data.data._embedded.project_widgets;
        context.commit(SET_PROJECT_WIDGET_LIST, projectWidgets);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_PROJECT_WIDGET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_CALENDAR_WIDGET_INFO](context, payload) {
    return ApiService.get("api/v1/widget_data/calendar/project_widgets/" + payload.widgetId, { params: payload.params })
      .then((res) => {
        const calendarDetail = res.data.data._embedded.activities;
        context.commit(SET_CALENDAR_WIDGET_INFO, calendarDetail);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_CALENDAR_WIDGET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_MEMBER_ONLINE_WIDGET_INFO](context, payload) {
    return ApiService.get("api/v1/widget_data/members_online/project_widgets/" + payload.widgetId, { params: payload.params })
      .then((res) => {
        const usersDetail = res.data.data._embedded.users;
        context.commit(SET_MEMBER_ONLINE_WIDGET_INFO, usersDetail);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_MEMBER_ONLINE_WIDGET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_RECENT_ACTIVITY_WIDGET_INFO](context, payload) {
    return ApiService.get("api/v1/widget_data/recent_activity/project_widgets/" + payload.widgetId, { params: payload.params })
      .then((res) => {
        const responsesDetail = res.data.data._embedded.activity_responses;
        context.commit(SET_RECENT_ACTIVITY_WIDGET_INFO, responsesDetail);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_RECENT_ACTIVITY_WIDGET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_COMMUNITY_MANAGER_WIDGET_INFO](context, payload) {
    return ApiService.get("api/v1/widget_data/community_managers/project_widgets/" + payload.widgetId, { params: payload.params })
      .then((res) => {
        const userDetail = res.data.data._embedded.project_profiles;
        context.commit(SET_COMMUNITY_MANAGER_WIDGET_INFO, userDetail);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_COMMUNITY_MANAGER_WIDGET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_POLL_WIDGET_INFO](context, payload) {
    return ApiService.get("api/v1/widget_data/poll/project_widgets/" + payload.widgetId, { params: payload.params })
      .then((res) => {
        const activityDetail = res.data;
        activityDetail.widgetId = payload.widgetId;
        context.commit(SET_POLL_WIDGET_INFO, activityDetail);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_POLL_WIDGET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_WIDGET_LIST](context) {
    context.commit(RESET_PROJECT_WIDGET_LIST);
  },
  [CLEAR_POLL_WIDGET_INFO](context, payload) {
    context.commit(RESET_POLL_WIDGET_INFO, payload.widgetId);
  },
  [CLEAR_PROJECT_WIDGET_ERRORS](context) {
    context.commit(RESET_PROJECT_WIDGET_ERRORS);
  },
  [CLEAR_CALENDAR_WIDGET_ERRORS](context) {
    context.commit(RESET_CALENDAR_WIDGET_ERRORS);
  },
  [CLEAR_POLL_WIDGET_ERRORS](context) {
    context.commit(RESET_POLL_WIDGET_ERRORS);
  },
  [CLEAR_MEMBER_ONLINE_WIDGET_ERRORS](context) {
    context.commit(RESET_MEMBER_ONLINE_WIDGET_ERRORS);
  },
  [CLEAR_RECENT_ACTIVITY_WIDGET_ERRORS](context) {
    context.commit(RESET_RECENT_ACTIVITY_WIDGET_ERRORS);
  },
  [CLEAR_COMMUNITY_MANAGER_WIDGET_ERRORS](context) {
    context.commit(RESET_COMMUNITY_MANAGER_WIDGET_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_WIDGET_LIST](state, project_widgets) {
    state.project_widget_list = project_widgets;
  },
  [RESET_PROJECT_WIDGET_LIST](state) {
    state.project_widget_list = {};
  },
  [SET_CALENDAR_WIDGET_INFO](state, calendar_detail) {
    state.calendar_widget_info = calendar_detail;
  },
  [RESET_CALENDAR_WIDGET_INFO](state) {
    state.calendar_widget_info = {};
  },
  [SET_MEMBER_ONLINE_WIDGET_INFO](state, user_detail) {
    state.member_online_widget_info = user_detail;
  },
  [RESET_MEMBER_ONLINE_WIDGET_INFO](state) {
    state.member_online_widget_info = {};
  },
  [SET_RECENT_ACTIVITY_WIDGET_INFO](state, responses_detail) {
    state.recent_activity_widget_info = responses_detail;
  },
  [RESET_RECENT_ACTIVITY_WIDGET_INFO](state) {
    state.recent_activity_widget_info = {};
  },
  [SET_COMMUNITY_MANAGER_WIDGET_INFO](state, user_detail) {
    state.community_manager_widget_info = user_detail;
  },
  [RESET_COMMUNITY_MANAGER_WIDGET_INFO](state) {
    state.community_manager_widget_info = {};
  },
  [SET_POLL_WIDGET_INFO](state, activity_detail) {
    state.poll_widget_info.push(activity_detail);
  },
  [RESET_POLL_WIDGET_INFO](state, widgetId) {
    state.poll_widget_info = state.poll_widget_info.filter(function(obj) {
      return obj.widgetId !== widgetId;
    });
  },
  [SET_PROJECT_WIDGET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [SET_CALENDAR_WIDGET_ERRORS](state, errors) {
    state.calendar_widget_errors = errors;
  },
  [SET_MEMBER_ONLINE_WIDGET_ERRORS](state, errors) {
    state.member_online_widget_errors = errors;
  },
  [SET_RECENT_ACTIVITY_WIDGET_ERRORS](state, errors) {
    state.recent_activity_widget_errors = errors;
  },
  [SET_COMMUNITY_MANAGER_WIDGET_ERRORS](state, errors) {
    state.community_manager_widget_errors = errors;
  },
  [SET_POLL_WIDGET_ERRORS](state, errors) {
    state.poll_widget_errors = errors;
  },
  [RESET_CALENDAR_WIDGET_ERRORS](state) {
    state.calendar_widget_errors = {};
  },
  [RESET_MEMBER_ONLINE_WIDGET_ERRORS](state) {
    state.member_online_widget_errors = {};
  },
  [RESET_RECENT_ACTIVITY_WIDGET_ERRORS](state) {
    state.recent_activity_widget_errors = {};
  },
  [RESET_COMMUNITY_MANAGER_WIDGET_ERRORS](state) {
    state.community_manager_widget_errors = {};
  },
  [RESET_POLL_WIDGET_ERRORS](state) {
    state.poll_widget_errors = {};
  },
  [RESET_PROJECT_WIDGET_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
