import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ACTIVITY_LIST = "populateActivityList";
export const POPULATE_MARKUP_QUESTION_LIST = "populateMarkupQuestionList";
export const POPULATE_ACTIVITY_INFO = "populateActivityInfo";
export const POPULATE_ACTIVITY_STATS = "populateActivityStats";

export const DELETE_ACTIVITY_BLOG_REPLY = "deleteActivityBlogReply";

export const POST_ACTIVITY_SURVEY_RESPONSE = "postActivitySurveyResponse";

export const CLEAR_ACTIVITY_LIST = "clearActivityList";
export const CLEAR_ACTIVITY_INFO = "clearActivityInfo";
export const CLEAR_ACTIVITY_ERRORS = "clearProjectErrors";

// mutation types
export const SET_MARKUP_QUESTION_LIST = "setMarkupQuestionList";
export const SET_ACTIVITY_LIST = "setActivityList";
export const RESET_ACTIVITY_LIST = "resetActivityList";
export const SET_ACTIVITY_INFO = "setActivityInfo";
export const RESET_ACTIVITY_INFO = "resetActivityInfo";
export const SET_ACTIVITY_ERRORS = "setActivityErrors";
export const RESET_ACTIVITY_ERRORS = "resetActivityErrors";
export const SET_ACTIVITY_STATS = "setActivityStats";
export const RESET_ACTIVITY_STATS = "resetActivityStats";

export const SET_ACTIVITY_RESPONSE_INFO = "setActivityResponseInfo";

const state = {
  activity_errors: {},
  activity_list: [],
  activity_info: [],
  markup_question_list: [],
  activity_stats: [],
  new_activity_response_info: []
};

const getters = {
  activityErrors(state) {
    return state.errors;
  },
  activityList(state) {
    return state.activity_list;
  },
  activityInfo(state) {
    return state.activity_info;
  },
  activityStats(state) {
    return state.activity_stats;
  },
  newActivityResponseInfo(state) {
    return state.new_activity_response_info;
  },
  markupQuestionList(state) {
    return state.markup_question_list;
  },
};

const actions = {
  [POPULATE_MARKUP_QUESTION_LIST](context) {
    return context.commit(SET_MARKUP_QUESTION_LIST);
  },
  [POPULATE_ACTIVITY_LIST](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/activities", {
      params: payload.params
    })
      .then((res) => {
        const activities = res.data.data._embedded.activities;
        context.commit(SET_ACTIVITY_LIST, activities);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_INFO](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/activities/" + payload.activityId, {
      params: payload.params
    })
      .then((res) => {
        const activity = res.data.data;
        context.commit(SET_ACTIVITY_INFO, activity);
        return activity;
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_STATS](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/activity_stats", {
      params: {}
    }).then((res) => {
      const activityStats = res.data;
      context.commit(SET_ACTIVITY_STATS, activityStats);
    });
  },
  [POST_ACTIVITY_SURVEY_RESPONSE](context, payload) {
    return ApiService.post("api/v1/activities/" + payload.activity_id + "/activity_responses", payload.data).then((res) => {
      const activityRespsonse = res.data.data;
      context.commit(SET_ACTIVITY_RESPONSE_INFO, activityRespsonse);
    });
  },
  [DELETE_ACTIVITY_BLOG_REPLY](context, payload) {
    return ApiService.delete("api/v1/activities/" + payload.activity_id + "/activity_responses/" + payload.activity_response_id).then((res) => {
      Vue.$log.info("deleted");
      Vue.$log.info(res);
      // const activityRespsonse = res.data.data;
      // context.commit(SET_ACTIVITY_RESPONSE_INFO, activityRespsonse);
    });
  },
  [CLEAR_ACTIVITY_LIST](context) {
    context.commit(RESET_ACTIVITY_LIST);
  },
  [CLEAR_ACTIVITY_INFO](context) {
    context.commit(RESET_ACTIVITY_INFO);
  },
  [CLEAR_ACTIVITY_ERRORS](context) {
    context.commit(RESET_ACTIVITY_ERRORS);
  }
};

const mutations = {
  [SET_MARKUP_QUESTION_LIST](state) {
    // let question = state.activity_info.engagement_questions;
    // let arr = [];
    // for (let index = 0; index < question.length; index++) {
    //   const element = question[index];
    //   arr.push(element);
    //   const nexElement = question[index + 1];
    //   if (nexElement) {
    //     if (element.page < nexElement.page) {
    //       arr.push({ id: null, is_page_break: true });
    //     }
    //   }
    // }
    state.markup_question_list = state.activity_info.engagement_questions;
  },
  [SET_ACTIVITY_LIST](state, activities) {
    state.activity_list = activities;
  },
  [RESET_ACTIVITY_LIST](state) {
    state.activity_list = [];
  },
  [SET_ACTIVITY_INFO](state, activity) {
    state.activity_info = activity;
  },
  [RESET_ACTIVITY_INFO](state) {
    state.activity_info = [];
  },
  [SET_ACTIVITY_ERRORS](state, errors) {
    state.activity_errors = errors;
  },
  [RESET_ACTIVITY_ERRORS](state) {
    state.activity_errors = {};
  },
  [SET_ACTIVITY_STATS](state, activityStats) {
    state.activity_stats = activityStats;
  },
  [SET_ACTIVITY_RESPONSE_INFO](state, activityResponse) {
    state.new_activity_response_info = activityResponse;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
