<template>
  <a
    href="javascript:void(0)"
    @click="toggleFavourite"
    class="btn btn-icon-warning btn-sm btn-text-dark-50 bg-hover-light-warning btn-hover-text-warning rounded font-weight-bolder font-size-xs p-2"
    v-b-tooltip.hover.bottom
    :title="tooltip"
    :disabled="submitting"
    :style="legacy ? 'cursor: not-allowed' : null"
  >
    <span class="svg-icon svg-icon-md svg-icon-danger pr-1">
      <i
        class="fa-star text-warning fa-lg"
        :class="{ 'far' : myFavourite == null, 'fas' : myFavourite != null }"
      >
      </i>
    </span>
    {{ totalFavourites }}
  </a>
</template>

<script>
import { mapGetters } from "vuex";

import {
  CREATE_FAVOURITE_ENGAGEMENTS,
  DELETE_FAVOURITE_ENGAGEMENTS
} from "@/core/services/store/activity/activity_favourite_engagements.module";

export default {
  name: "EngagementFavourite",

  props: {
    favourites: { type: Array, required: true },
    activityId: { type: Number, required: true },
    favouritableType: { type: String, required: true },
    favouritableId: { type: Number, required: true },
    legacy: { type: Boolean, required: false, default: false }
  },

  data() {
    return {
      myFavourite: null,
      submitting: false
    }
  },

  mounted() {
    let currentUserId = this.currentUser.id;

    let myFavourite = this.favourites.filter(function( favourite ) {
      return favourite.user_id == currentUserId;
    });

    if (myFavourite.length == 1) {
      this.myFavourite = myFavourite[0];
    }
  },

  methods: {
    toggleFavourite: function() {

      if (this.submitting || this.legacy) {
        return;
      }

      this.submitting = true;

      let action = null;
      let payload = {};

      if (this.iHaveEngaged) {
        action = DELETE_FAVOURITE_ENGAGEMENTS;
        payload = {
          activityId: this.activityId,
          activityFavouriteEngagementId: this.myFavourite.id
        };
      } else {
        action = CREATE_FAVOURITE_ENGAGEMENTS;
        payload = {
          activityId: this.activityId,
          data: {
            favouritable_type: this.favouritableType,
            favouritable_id: this.favouritableId
          }
        };
      }

      this.$store
        .dispatch(action, payload)
        .then(res => {
          if (this.iHaveEngaged) {
            this.myFavourite = null;
          } else {
            this.myFavourite = res;
          }
        })
        .finally(() => {
          this.submitting = false;
        })
    }
  },

  computed: {
    ...mapGetters(["currentUser"]),

    otherFavourites: function() {
      let currentUserId = this.currentUser.id;

      return this.favourites.filter(function( favourite ) {
        return favourite.user_id !== currentUserId;
      });
    },

    iHaveEngaged: function() {
      return (this.myFavourite == null) ? false : true;
    },

    totalFavourites: function() {
      if (this.iHaveEngaged) {
        return this.otherFavourites.length + 1;
      } else {
        return this.otherFavourites.length;
      }
    },

    tooltip: function() {
      let tooltip = null;

      if (this.myFavourite != null) {
        return `${this.$t("REVIEW.STREAM.FAVOURITE_SUMMARY_ME")}`;
      } else if (this.totalFavourites == 1) {
        return this.totalFavourites + `${this.$t("REVIEW.STREAM.FAVOURITE_SUMMARY_SINGULAR")}`;
      } else if (this.totalFavourites > 1 || this.totalFavourites == 0) {
        return this.totalFavourites + `${this.$t("REVIEW.STREAM.FAVOURITE_SUMMARY_PLURAL")}`;
      };

      return tooltip;
    }
  }
}
</script>