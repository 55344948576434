import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const DELETE_MARKUP_RESPONSES = "deleteMarkupRepsonses";

// mutation types
export const SET_ACTIVITY_MARKUP_RESPONSES_ERRORS = "setActivityMarkupRepsonsesErrors";

const state = {
  activity_markup_responses_errors: {},
};

const getters = {
  activityMarkupRepsonsesErrors(state) {
    return state.activity_markup_responses_errors;
  },
};

const actions = {
  [DELETE_MARKUP_RESPONSES](context, payload) {
    return ApiService.delete("api/v1/activities/" + payload.activity_id + "/activity_markup_responses/" + payload.id)
      .then((res) => {
        Vue.$log.info("deleted");
        Vue.$log.info(res);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_MARKUP_RESPONSES_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  }
};

const mutations = {
  [SET_ACTIVITY_MARKUP_RESPONSES_ERRORS](state, activity_markup_responses_errors) {
    state.activity_markup_responses_errors = activity_markup_responses_errors;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
