import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

/* Generic */
import generic from "@/modules/together-helpers/vuex/generic.module";

/* System */
import asset from "./system/asset.module";
import auth from "@/modules/together-authentication/store/auth.module";
import htmlClass from "./system/htmlclass.module";
import config from "./system/config.module";
import breadcrumbs from "./system/breadcrumbs.module";
import system from "./system/system.module";
import menu from "./system/menu.module";
import i18n from "@/modules/together-helpers/components/generic-features/languages/vuex/i18n.module";
import helpscout from "./system/helpscout.module";
import zendesk from "@/modules/together-helpers/vuex/system/zendesk.module";
import notification from "@/modules/together-helpers/components/generic-features/notifications/vuex/notifications.module";
import version from "@/modules/together-helpers/vuex/system/version.module";

/* Core */
import client from "./core/client.module";
import user from "./core/user.module";

/* Project */
import project from "./project/project.module";
import projectProfile from "./project/project_profile.module";
import projectIntroduction from "./project/project_introductions.module";
import projectSegments from "./project/project_segments.module";
import projectSegmentations from "./project/project_segmentations.module";
import projectTextAnalysis from "./project/project_text_analysis.module";
import projectWidgets from "./project/project_widgets.module";
import projectBrands from "@/modules/together-helpers/vuex/project/project_brand.module";
import projectBasicProfileQuestions from "./project/project_basic_profile_questions.module";
import projectExtendedProfileQuestions from "./project/project_extended_profile_questions.module";
import projectRoomSetup from "./project/project_room_setup.module";

/* Forum */
import projectForum from "@/modules/together-helpers/vuex/project/project_forum.module.js";
import projectForumResponse from "@/modules/together-helpers/vuex/project/project_forum_response.module.js";
import projectForumResponseReply from "@/modules/together-helpers/vuex/project/project_forum_response_reply.module.js";
import projectForumResponseSentiment from "@/modules/together-helpers/vuex/project/project_forum_response_sentiment.module.js";

/* Activity */
import activity from "./activity/activity.module";
import activity_blog_responses from "./activity/activity_blog_responses.module";
import activity_diary_responses from "./activity/activity_diary_responses.module";
import activity_discussion_responses from "./activity/activity_discussion_responses.module";
import activity_survey_responses from "./activity/activity_survey_responses.module";
import activity_mark_up from "./activity/activity_mark_up.module";
import activity_picture_book_responses from "./activity/activity_picture_book_responses.module";
import activity_ideastorm_responses from "./activity/activity_ideastorm_responses.module";
import activity_external_responses from "@/modules/together-helpers/vuex/activity/response/activity_external_responses.module.js";
import activity_poll_responses from "./activity/activity_poll_responses.module";
import activity_reply_engagements from "./activity/activity_reply_engagements.module";
import activity_sentiment_engagements from "./activity/activity_sentiment_engagements.module";
import activity_tag_engagements from "./activity/activity_tag_engagements.module";
import activity_note_engagements from "./activity/activity_note_engagements.module";
import activity_favourite_engagements from "./activity/activity_favourite_engagements.module";
import survey_question_choice from "./activity/survey_question_choice.module.js";
import survey_question from "./activity/survey_question.module.js";
import diary_question from "./activity/diary_question.module.js";
import interaction from "@/modules/together-helpers/vuex/activity/interaction/interactions.module";

/* Activity (Imported from Vue3 Project) */
import response from "@/modules/together-helpers/vuex/activity/response/responses.module";
import responseLegacy from "@/modules/together-helpers/vuex/activity/response/responses_legacy.module";

/* Page */
import page from "./page/page.module";

/* Auth */
import registerWorkflow from "@/modules/together-authentication/store/register_workflow.module";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    auth: {
      errors: state.auth.errors,
      user: state.auth.user,
      isAuthenticated: state.auth.isAuthenticated
    }
  })
});

export default new Vuex.Store({
  modules: {
    generic,
    asset,
    auth,
    htmlClass,
    config,
    breadcrumbs,
    system,
    menu,
    i18n,
    helpscout,
    zendesk,
    notification,
    version,
    client,
    user,
    project,
    projectProfile,
    projectIntroduction,
    projectSegments,
    projectSegmentations,
    projectTextAnalysis,
    projectWidgets,
    projectBrands,
    projectBasicProfileQuestions,
    projectExtendedProfileQuestions,
    projectRoomSetup,
    projectForum,
    projectForumResponse,
    projectForumResponseReply,
    projectForumResponseSentiment,
    activity,
    page,
    activity_blog_responses,
    activity_diary_responses,
    activity_discussion_responses,
    activity_survey_responses,
    activity_mark_up,
    activity_picture_book_responses,
    activity_ideastorm_responses,
    activity_external_responses,
    activity_poll_responses,
    activity_reply_engagements,
    activity_sentiment_engagements,
    activity_tag_engagements,
    activity_note_engagements,
    activity_favourite_engagements,
    survey_question_choice,
    survey_question,
    diary_question,
    interaction,
    response,
    responseLegacy,
    registerWorkflow
  },
  plugins: [vuexLocal.plugin]
});
