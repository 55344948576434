import { render, staticRenderFns } from "./Replies.vue?vue&type=template&id=6591b391&scoped=true&"
import script from "./Replies.vue?vue&type=script&lang=js&"
export * from "./Replies.vue?vue&type=script&lang=js&"
import style0 from "./Replies.vue?vue&type=style&index=0&id=6591b391&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6591b391",
  null
  
)

export default component.exports