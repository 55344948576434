import VuexHelpers from "@/modules/together-helpers/vuex/vuex-helpers.js";

//action types
export const CREATE_ACTIVITY_RESPONSE = "createActivityResponse";
export const DELETE_ACTIVITY_RESPONSE = "deleteActivityResponse";

const state = {};

const getters = {};

const actions = {
  [CREATE_ACTIVITY_RESPONSE](context, payload) {
    payload.response = {
      ...payload.response,
      ...{
        x_attributes: ['engagementSummary'],
        x_relations: {
          activity: 'with',
          projectAssets: 'with',
          activityType: 'with',
          user: 'with',
          engagementQuestionResponses: 'with',
          engagementQuestionPinResponses: 'with',
          directInteractions: 'with'
        }
      }
    };
    let url = `api/v1/activities/${payload.activityId}/engagement_responses`;
    return VuexHelpers.create("ActivityResponse", context, url, payload.response, false);
  },
  [DELETE_ACTIVITY_RESPONSE](context, payload) {
    let url = `api/v1/activities/${payload.activity_id}/engagement_responses/${payload.engagement_response_id}`;
    return VuexHelpers.delete("ActivityResponse", context, url, payload, false);
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};