import ApiService from "@/core/services/api.service";
import Vue from "vue";

//action types
export const POPULATE_ARTICLES = "PopulateArticles";
export const CLEAR_ARTICLES = "clearArticles";
export const CREATE_HELP_REQUEST = "createHelpRequest";
export const CLEAR_HELPSCOUT_ERRORS = "clearHelpscoutErrors";

// mutation types
export const SET_ARTICLES_LIST = "setArticlesList";
export const RESET_ARTICLES_LIST = "resetArticlesList";
export const SET_HELPSCOUT_ERRORS = "setHelpscoutErrors";
export const RESET_HELPSCOUT_ERRORS = "resetHelpscoutErrors";

const state = {
  articles_list: [],
  errors: {}
};

const getters = {
  helpscoutArticles(state) {
    return state.articles_list;
  },
  helpscoutErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_ARTICLES](context, payload) {
    return ApiService.get("api/v1/helpscout", {
      params: payload.params
    }).then(res => {
        const results = res.data;
        context.commit(SET_ARTICLES_LIST, results);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_HELPSCOUT_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_ARTICLES](context) {
    context.commit(RESET_ARTICLES_LIST);
  },
  [CREATE_HELP_REQUEST](context, payload) {
    return ApiService.post("api/v1/helpscout/", payload)
      .then(res => {
        return res;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_HELPSCOUT_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_HELPSCOUT_ERRORS](context) {
    context.commit(RESET_HELPSCOUT_ERRORS);
  },
};

const mutations = {
  [SET_ARTICLES_LIST](state, articles) {
    state.articles_list = articles;
  },
  [RESET_ARTICLES_LIST](state) {
    state.articles_list = [];
  },
  [SET_HELPSCOUT_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_HELPSCOUT_ERRORS](state) {
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};