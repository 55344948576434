import VuexHelpers from "../../vuex-helpers";

//action types
export const CREATE_ENGAGEMENT_INTERACTION  = "createEngagementInteraction";
export const DELETE_ENGAGEMENT_INTERACTION  = "deleteEngagementInteraction";

const state = {};

const getters = {};

const actions = {
  [CREATE_ENGAGEMENT_INTERACTION](context, payload) {
    let url = `api/v1/engagement_responses/${payload.engagement_response_id}/engagement_interactions`;
    return VuexHelpers.create("EngagementInteraction", context, url, payload, false);
  },
  [DELETE_ENGAGEMENT_INTERACTION](context, payload) {
    let url = `api/v1/engagement_responses/${payload.engagement_response_id}/engagement_interactions/${payload.engagement_interaction_id}`;
    return VuexHelpers.delete("EngagementInteraction", context, url, payload, false);
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};