<template>
  <div>
    <b-form-group
      :id="id + '-group'"
      :label="computedLabel"
      :label-sr-only="!displayLabel"
      :label-for="id"
      class="w-100 pt-5"
      :class="{ 'mb-0 ': model.$error || computedSlim || helper != undefined }"
    >
      <b-form-textarea
        :id="id"
        :name="id"
        v-model="model.$model"
        :state="validateState()"
        :placeholder="computedPlaceholder"
        v-on:keyup.tab="nextElementFocus($event)"
        @change="textAreaChanged"
        :disabled="disabled || submissionStates.submitting === true"
        :rows="rows ? rows : 6"
        :size="size ? size : 'md'"
      ></b-form-textarea>
    </b-form-group>
    <div class="custom-validation-failure mb-7" v-if="model.$error">
      {{ error }}
    </div>
    <span v-if="helper != undefined" class="form-text text-muted px-2 mb-5">
      {{ helper }}
    </span>
  </div>
</template>

<script>
export default {
  name: "InputGenericTextArea",

  props: {
    id: { type: String, required: true },
    disabled: { type: Boolean, required: false },
    error: { type: String, required: false },
    label: { type: String, required: false },
    model: { type: Object, required: true },
    submissionStates: { type: Object, required: true },
    placeholder: { type: String, required: false },
    prepResponse: { type: String, required: false },
    rows: { type: String, required: false },
    size: { type: String, required: false },
    slim: { type: Boolean, required: false },
    helper: { type: String, required: false }
  },

  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      const boolean = $dirty ? !$error : null;
      const sameAsPrep = this.model.$model == this.prepResponse;
      return boolean && !sameAsPrep;
    },
    nextElementFocus($event) {
      this.$emit("nextInputFocus", $event);
    },
    textAreaChanged() {
      this.$emit("valueChanged");
    }
  },

  mounted() {
    if (!this.model.$model && this.prepResponse) {
      let string = this.prepResponse.replace(/<\/?[^>]+>/ig, " ");
      this.model.$model = string;
    }
  },

  computed: {
    computedLabel: function() {
      if (this.label !== undefined) {
        if (this.model.$params.hasOwnProperty("required")) {
          return this.label + "*";
        } else {
          return this.label;
        }
      } else {
        return "";
      }
    },
    displayLabel: function() {
      if (this.label != undefined) {
        if (this.label.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    computedPlaceholder: function() {
      if (this.placeholder == undefined) {
        return this.label;
      } else {
        return this.placeholder;
      }
    },
    computedSlim: function() {
      if (this.slim != undefined) {
        return this.slim;
      } else {
        return false;
      }
    }
  }
};
</script>
