import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Vue from "vue";

// action types
export const LOGIN = "login";
export const LOGOUT = "logout";
export const RESET = "reset";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const CLEAR = "clear";
export const RESEND_VERIFY = "resendVerify";
export const VERIFY_EMAIL = "verify";
export const VERIFY_TOKEN = "verifyToken";
export const REQUEST_RESET = "requestReset";
export const RESET_PASSWORD = "resetPassword";
export const VERIFY_AUTH = "verifyAuth";
export const REFRESH_TOKEN = "refreshToken";
export const VERIFY_REGISTER_URL = "verifyRegisterUrl";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const PURGE_ERRORS = "purgeErrors";
export const VERIFY = "verify";
export const SET_COOKIES = "setCookies";

const state = {
  errors: {},
  user: {},
  isAuthenticated: false,
  tokens: {
    access_token: null,
    refresh_token: null
  }
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  authenticationErrors(state) {
    return state.errors;
  },
  authTokens(state) {
    return state.tokens;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/login", credentials)
        .then(({ data }) => {
          context.commit(SET_COOKIES, data)
          context.dispatch(VERIFY_AUTH, 1)
            .then((res) => {
              resolve(res);
            })              
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_ERROR, error.response.data);
          } else {
            Vue.$log.error(error.response);
          }
          reject();
        });
    })
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/logout")
        .then(({ data }) => {
          context.commit(PURGE_AUTH);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [RESET](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(payload.route, payload)
        .then(function() {
          resolve();
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data);
          reject();
        });
    });
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
  [CLEAR](context) {
    context.commit(PURGE_ERRORS);
  },
  [RESEND_VERIFY](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/verification-notification")
        .then(function() {
          resolve();
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_EMAIL](context, verification) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/verify-email", verification)
        .then(data => {
          context.commit(VERIFY, data.data);
          resolve();
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_TOKEN](context, token) {
    return new Promise((resolve, reject) => {
      ApiService.get("/auth/register/token/" + token)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [REQUEST_RESET](context, email) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/request-reset", {
        email: email.email,
        origin: window.location.origin
      })
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [RESET_PASSWORD](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/handle-reset", data)
        .then(() => {
          resolve();
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data);
          reject();
        });
    });
  },
  [VERIFY_AUTH](context, getRedirect = 0) {
    return new Promise((resolve, reject) => {
      ApiService.get("api/v1/auth-user", {
        params: {
          scope: process.env.VUE_APP_SUBMODULE_IDENTIFIER.toLowerCase(),
          domain: window.location.host.split('.')[0],
          node: process.env.VUE_APP_SUBMODULE_IDENTIFIER,
          get_redirect: getRedirect
        }
      })
        .then((res) => {
          context.commit(SET_AUTH, res.data.data);
          resolve(res);
        })
        .catch(({ response }) => {
          context.commit(PURGE_AUTH);
          reject(response);
        });
    });
  },
  [REFRESH_TOKEN](context, token) {
    return new Promise((resolve, reject) => {
      ApiService.post("oauth/token", {
        grant_type: 'refresh_token',
        refresh_token: token,
        client_id: process.env.VUE_APP_PASSPORT_ID,
        client_secret: process.env.VUE_APP_PASSPORT_SECRET
      })
        .then(({ data }) => {
          context.commit(SET_COOKIES, data)
          resolve();
        })
        .catch(() => {
          context.commit(PURGE_AUTH);
          reject();
        });
    });
  },
  [VERIFY_REGISTER_URL](context, payload) {
    return ApiService.post("auth/verify-registration-url", payload)
      .then((res) => {
        if (res.data.action == 'input_redirect') {
          context.commit(SET_COOKIES, { access_token: res.data.token, refresh_token: null });
        }
        return res.data;
      })
      .catch((error) => {
        context.commit(SET_ERROR, error.response.data);
        return error;
      })
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();

    document.cookie = "access_token=null;path=/;domain=" + document.domain.split('.').reverse().splice(0,2).reverse().join('.');
    document.cookie = "refresh_token=null;path=/;domain=" + document.domain.split('.').reverse().splice(0,2).reverse().join('.');
  },
  [PURGE_ERRORS](state) {
    state.errors = {};
  },
  [VERIFY](state, verified_at) {
    state.user.email_verified_at = verified_at;
  },
  [SET_COOKIES](state, payload) {
    state.tokens.access_token = payload.access_token;
    state.tokens.refresh_token = payload.refresh_token;

    document.cookie = "access_token=" + payload.access_token + ";path=/;domain=" + document.domain.split('.').reverse().splice(0,2).reverse().join('.');
    document.cookie = "refresh_token=" + payload.refresh_token + ";path=/;domain=" + document.domain.split('.').reverse().splice(0,2).reverse().join('.');
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
