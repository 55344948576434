<template>
  <span> {{ displayUserName }} </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GenericUserName",

  props: {
    user: { type: Object, required: true }
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    displayUserName: function() {
      return this.user.display_name;
    }
  }
}
</script>