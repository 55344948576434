import Vue from "vue";
import ApiService from "@/core/services/api.service";

class VuexHelpers {

  static create(reference, context, url, payload, mutate = true) {
    let addObjectMutation = `add${reference}`;
    let addErrorMutation = `set${reference}Errors`;
    return ApiService.post(url, payload.data)
      .then(res => {
        if (mutate) {
          context.commit(addObjectMutation, res.data.data);
        }
        return res.data.data;
      })
      .catch(error => {
        if (error.response && mutate) {
          context.commit(addErrorMutation, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  }

  static update(reference, context, url, payload, mutate = true) {
    let resetObjectMutation = `reset${reference}`;
    let addErrorMutation = `set${reference}Errors`;
    return ApiService.put(url, payload.data)
      .then(res => {
        if (mutate) {
          context.commit(resetObjectMutation, res.data.data);
        }
        return res.data.data;
      })
      .catch(error => {
        if (error.response && mutate) {
          context.commit(addErrorMutation, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  }

  static delete(reference, context, url, strippedId) {
    let stripObjectMutation = `strip${reference}`;
    let addErrorMutation = `set${reference}Errors`;
    return ApiService.delete(url)
      .then(res => {
        context.commit(stripObjectMutation, strippedId);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(addErrorMutation, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  }

  static add(state, reference, newObject) {
    let listAttribute = `${reference}_list`;
    let infoAttribute = `${reference}_info`;

    state[listAttribute].push(newObject);
    state[infoAttribute] = newObject;
  }

  static reset(state, reference, updatedObject) {
    let listAttribute = `${reference}_list`;
    let infoAttribute = `${reference}_info`;

    state[infoAttribute] = updatedObject;
    state[listAttribute] = [
      ...state[listAttribute].map(
        existingObject => existingObject.id !== updatedObject.id ? existingObject : {...existingObject, ...updatedObject}
      )
    ]
  }

  static strip(state, reference, removedObjectId) {
    let listAttribute = `${reference}_list`;
    state[listAttribute] = state[listAttribute].filter(function( listItem ) {
      return listItem.id !== removedObjectId;
    });
  }

}

export default VuexHelpers;