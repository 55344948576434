<template>
  <div>
    <b-form-group
      :id="id + '-group'"
      v-slot="{ ariaDescribedby }"
      class="mb-0"
    >
      <b-form-checkbox
        :id="id"
        v-model="model.$model"
        :name="id"
        :aria-describedby="ariaDescribedby"
        value="1"
        unchecked-value="0"
        size="lg"
        @change="$emit('valueChanged', $event)"
        :disabled="computedDisabled"
      >
        <p 
          class="pt-1"
          :class="{ 'mb-0' : computedSlim }"
          v-if="tooltip != undefined"
          v-b-tooltip.hover.right
          :title="tooltip"
        >
          {{ label }}
        </p>
        <p 
          v-else
          class="pt-1" 
          :class="{ 'mb-0' : computedSlim }"
        >
          {{ label }}
        </p>
      </b-form-checkbox>
    </b-form-group>
    <div class="custom-validation-failure mb-7" v-if="model.$error">
      {{ error }}
    </div>
  </div>
</template>
 
<script>
export default {
  name: "InputGenericCheckbox",

  props: {
    id: { type: String, required: true },
    model: { type: Object, required: true },
    disabled: { type: Boolean, required: false },
    showInline: { type: Boolean, required: false },
    error: { type: String, required: false },
    label: { type: String, required: false },
    submissionStates: { type: Object, required: true },
    tooltip: { type: String, required: false },
    slim: { type: Boolean, required: false }
  },

  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    }
  },

  computed: {
    computedDisabled: function() {
      if (this.disabled != undefined) {
        return this.disabled;
      } else {
        return false;
      }
    },

    computedSlim: function() {
      if (this.slim != undefined) {
        return this.slim;
      } else {
        return false;
      }
    }
  }
};
</script>
