import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_BASIC_PROFILE_QUESTIONS = "populateBasicProfileQuestions";
export const POPULATE_BASIC_PROFILE_QUESTION = "populateBasicProfileQuestion";
export const CLEAR_BASIC_PROFILE_QUESTIONS = "clearBasicProfileQuestions";
export const CLEAR_BASIC_PROFILE_QUESTION = "clearBasicProfileQuestion";
export const CREATE_BASIC_PROFILE_QUESTION = "createBasicProfileQuestion";
export const UPDATE_BASIC_PROFILE_QUESTION = "updateBasicProfileQuestion";
export const DELETE_BASIC_PROFILE_QUESTION = "deleteBasicProfileQuestion";
export const CLEAR_BASIC_PROFILE_QUESTION_ERRORS = "clearBasicProfileQuestionErrors";

// mutation types
export const SET_BASIC_PROFILE_QUESTIONS = "setBasicProfileQuestions";
export const RESET_BASIC_PROFILE_QUESTIONS = "resetBasicProfileQuestions";
export const SET_BASIC_PROFILE_QUESTION = "setBasicProfileQuestion";
export const RESET_BASIC_PROFILE_QUESTION = "resetBasicProfileQuestion";
export const SET_BASIC_PROFILE_QUESTION_ERRORS = "setBasicProfileQuestionErrors";
export const RESET_BASIC_PROFILE_QUESTION_ERRORS = "resetBasicProfileQuestionErrors";

const state = {
  basic_profile_questions: [],
  basic_profile_question: {},
  errors: {}
};

const getters = {
  basicProfileQuestions(state) {
    return state.basic_profile_questions;
  },
  basicProfileQuestion(state) {
    return state.basic_profile_question;
  },
  basicProfileQuestionErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_BASIC_PROFILE_QUESTIONS](context, payload) {
    return ApiService.get(
      "api/v1/projects/" +
        payload.projectId +
        "/basic_profile_questions", {
          params: payload.params
        }
    )
    .then(res => {
      const basicProfileQuestions = res.data.data._embedded.basic_profile_questions;
      context.commit(SET_BASIC_PROFILE_QUESTIONS, basicProfileQuestions);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_BASIC_PROFILE_QUESTION_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [POPULATE_BASIC_PROFILE_QUESTION](context, payload) {
    return ApiService.get(
      "api/v1/projects/" +
        payload.projectId +
        "/basic_profile_questions/" +
        payload.basicProfileQuestionId, {
          params: payload.params
        }
    )
    .then(res => {
      const basicProfileQuestion = res.data.data;
      context.commit(SET_BASIC_PROFILE_QUESTION, basicProfileQuestion);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_BASIC_PROFILE_QUESTION_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [CLEAR_BASIC_PROFILE_QUESTIONS](context) {
    context.commit(RESET_BASIC_PROFILE_QUESTIONS);
  },
  [CLEAR_BASIC_PROFILE_QUESTION](context) {
    context.commit(RESET_BASIC_PROFILE_QUESTION);
  },
  [CREATE_BASIC_PROFILE_QUESTION](context, payload) {
    return ApiService.post(
      "api/v1/projects/" + payload.projectId + "/basic_profile_questions",
      payload.data
    )
    .then(res => {
      const basicProfileQuestion = res.data.data;
      context.commit(SET_BASIC_PROFILE_QUESTION, basicProfileQuestion);
      return basicProfileQuestion;
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_BASIC_PROFILE_QUESTION_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [UPDATE_BASIC_PROFILE_QUESTION](context, payload) {
    return ApiService.put(
      "api/v1/projects/" +
        payload.projectId +
        "/basic_profile_questions/" +
        payload.id,
      payload.data
    )
    .then(res => {
      const basicProfileQuestion = res.data.data;
      context.commit(SET_BASIC_PROFILE_QUESTION, basicProfileQuestion);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_BASIC_PROFILE_QUESTION_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [DELETE_BASIC_PROFILE_QUESTION](context, payload) {
    return ApiService.delete(
      "api/v1/projects/" +
        payload.projectId +
        "/basic_profile_questions/" +
        payload.id
    )
    .then(() => {
      context.commit(RESET_BASIC_PROFILE_QUESTION);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_BASIC_PROFILE_QUESTION_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [CLEAR_BASIC_PROFILE_QUESTION_ERRORS](context) {
    context.commit(RESET_BASIC_PROFILE_QUESTION_ERRORS);
  }
};

const mutations = {
  [SET_BASIC_PROFILE_QUESTIONS](state, basicProfileQuestions) {
    state.basic_profile_questions = basicProfileQuestions;
  },
  [RESET_BASIC_PROFILE_QUESTIONS](state) {
    state.basic_profile_questions = [];
  },
  [SET_BASIC_PROFILE_QUESTION](state, basicProfileQuestion) {
    state.basic_profile_question = basicProfileQuestion;
  },
  [RESET_BASIC_PROFILE_QUESTION](state) {
    state.basic_profile_question = {};
  },
  [SET_BASIC_PROFILE_QUESTION_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_BASIC_PROFILE_QUESTION_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
