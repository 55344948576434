import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_MARKUP_QUESTION_LIST_LEGACY = "populateMarkupQuestionListLegacy";
export const POPULATE_MARKUP_QUESTION_INFO = "populateMarkupQuestionInfo";

export const CREATE_MARKUP_RESPONSES = "createMarkupRepsonses";
export const CREATE_MARKUP_QUESTION = "createMarkupQuestion";
export const UPDATE_MARKUP_QUESTION = "updateMarkupQuestion";
export const DELETE_MARKUP_QUESTION = "deleteMarkupQuestion";
export const CLEAR_MARKUP_QUESTION_LIST = "clearMarkupQuestionList";
export const CLEAR_MARKUP_QUESTION_INFO = "clearMarkupQuestionInfo";
export const CLEAR_MARKUP_QUESTION_ERRORS = "clearMarkupQuestionErrors";

// mutation types
export const SET_MARKUP_QUESTION_LIST = "setMarkupQuestionList";
export const RESET_MARKUP_QUESTION_LIST = "resetMarkupQuestionList";
export const SET_MARKUP_QUESTION_LIST_META = "setMarkupQuestionListMeta";
export const RESET_MARKUP_QUESTION_LIST_META = "resetMarkupQuestionListMeta";
export const SET_MARKUP_QUESTION_INFO = "setMarkupQuestionInfo";
export const RESET_MARKUP_QUESTION_INFO = "resetMarkupQuestionInfo";
export const SET_MARKUP_QUESTION_ERRORS = "setMarkupQuestionErrors";
export const RESET_MARKUP_QUESTION_ERRORS = "resetMarkupQuestionErrors";
export const ADD_NEW_MARKUP_QUESTION = "addNewMarkupQuestion";
export const UPDATE_QUESTION = "updateQuestion";
export const REMOVE_MARKUP_QUESTION = "removeMarkupQuestion";

const state = {
  markup_question_errors: {},
  markup_question_list: [],
  markup_question_list_meta: {},
  markup_question_info: []
};

const getters = {
  markupQuestionErrors(state) {
    return state.markup_question_errors;
  },
  markupQuestionList2(state) {
    return state.markup_question_list;
  },
  markupQuestionListMeta(state) {
    return state.markup_question_list_meta;
  },
  markupQuestionInfo(state) {
    return state.markup_question_info;
  }
};

const actions = {
  [POPULATE_MARKUP_QUESTION_LIST_LEGACY](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_markup_questions",
      {
        params: payload.params
      }
    )
      .then(res => {
        const markupQuestions =
          res.data.data._embedded.activity_markup_questions;
        context.commit(SET_MARKUP_QUESTION_LIST, markupQuestions);
        if (res.data.meta != undefined) {
          context.commit(SET_MARKUP_QUESTION_LIST_META, res.data.meta);
        }
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_MARKUP_QUESTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_MARKUP_QUESTION_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_markup_questions/" +
        payload.id,
      {
        params: payload.params
      }
    )
      .then(res => {
        const markupQuestion = res.data.data;
        context.commit(SET_MARKUP_QUESTION_INFO, markupQuestion);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_MARKUP_QUESTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_MARKUP_RESPONSES](context, payload) {
    return ApiService.post(
      "api/v1/activities/" + payload.activityId + "/activity_markup_responses",
      payload.data
    )
      .then(res => {
        return res.data.data;
      })
      .catch(error => {
        Vue.$log.error(error);
        throw error;
      });
  },
  [CREATE_MARKUP_QUESTION](context, payload) {
    return ApiService.post(
      "api/v1/activities/" + payload.activityId + "/activity_markup_questions",
      payload.data
    )
      .then(res => {
        context.commit(ADD_NEW_MARKUP_QUESTION, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_MARKUP_QUESTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_MARKUP_QUESTION](context, payload) {
    return ApiService.put(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_markup_questions/" +
        payload.id,
      payload.data
    )
      .then(res => {
        context.commit(UPDATE_QUESTION, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_MARKUP_QUESTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_MARKUP_QUESTION](context, payload) {
    return ApiService.delete(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_markup_questions/" +
        payload.id
    )
      .then(res => {
        context.commit(REMOVE_MARKUP_QUESTION, payload.id);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_MARKUP_QUESTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_MARKUP_QUESTION_LIST](context) {
    context.commit(RESET_MARKUP_QUESTION_LIST);
    context.commit(RESET_MARKUP_QUESTION_LIST_META);
  },
  [CLEAR_MARKUP_QUESTION_INFO](context) {
    context.commit(RESET_MARKUP_QUESTION_INFO);
  },
  [CLEAR_MARKUP_QUESTION_ERRORS](context) {
    context.commit(RESET_MARKUP_QUESTION_ERRORS);
  }
};

const mutations = {
  [SET_MARKUP_QUESTION_LIST](state, markupQuestions) {
    let arr = [];
    for (let index = 0; index < markupQuestions.length; index++) {
      const element = markupQuestions[index];
      arr.push(element);
      const nexElement = markupQuestions[index + 1];
      if (nexElement) {
        if (element.page < nexElement.page) {
          arr.push({ id: null, is_page_break: true });
        }
      }
    }
    state.markup_question_list = arr;
  },
  [RESET_MARKUP_QUESTION_LIST](state) {
    state.markup_question_list = [];
  },
  [SET_MARKUP_QUESTION_LIST_META](state, markup_question_list_meta) {
    state.markup_question_list_meta = markup_question_list_meta;
  },
  [RESET_MARKUP_QUESTION_LIST_META](state) {
    state.markup_question_list_meta = {};
  },
  [SET_MARKUP_QUESTION_INFO](state, markupQuestion) {
    state.markup_question_info = markupQuestion;
  },
  [RESET_MARKUP_QUESTION_INFO](state) {
    state.markup_question_info = [];
  },
  [ADD_NEW_MARKUP_QUESTION](state, markupQuestion) {
    state.markup_question_list.push(markupQuestion);
  },
  [UPDATE_QUESTION](state, markupQuestion) {
    let newMarkupQuestionArray = [];
    newMarkupQuestionArray.push(markupQuestion);
    state.markup_question_list = state.markup_question_list.map(
      obj => newMarkupQuestionArray.find(o => o.id === obj.id) || obj
    );
  },
  [REMOVE_MARKUP_QUESTION](state, markupQuestionId) {
    state.markup_question_list = state.markup_question_list.filter(function(
      obj
    ) {
      return obj.id !== markupQuestionId;
    });
  },
  [SET_MARKUP_QUESTION_ERRORS](state, errors) {
    state.markup_question_errors = errors;
  },
  [RESET_MARKUP_QUESTION_ERRORS](state) {
    state.markup_question_errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
