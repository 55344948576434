class EngagementHelpers {

  static addActivityResponse(state, activity_response) {
    (!Array.isArray(state.activity_response_list)) ? state.activity_response_list = [] : null;

    if (activity_response._embedded != undefined && activity_response._embedded.responses != undefined) {
      activity_response._embedded.responses.forEach(function(response) {
        state.activity_response_list.unshift(response);
      })
    } else {
      state.activity_response_list.unshift(activity_response);
    }
  }

  /**
   * A basic engagement is where the type level response can be easily identified by `engagement.response_id`
   * 
   * @param { vuex state } state 
   * @param { base level response } response 
   * @param { engagement to be added } engagement 
   */
  static addBasicEngagement(state, response, engagement) {
    let index = state.activity_response_list.findIndex(obj =>
      obj.activity_response_id === engagement.activity_response_id &&
      obj.id === engagement.response_id
    );

    let relativeEngagements = response.engagementSummary[engagement.type];
    relativeEngagements.push(engagement);

    state.activity_response_list[index].engagementSummary[engagement.type] = relativeEngagements;
  }

  /**
   * A basic engagement is where the type level response can be easily identified by `engagement.response_id`
   * 
   * @param { vuex state } state 
   * @param { base level response } response 
   * @param { engagement to be added } engagement 
   */
  static stripBasicEngagement(state, response, engagement) {
    let index = state.activity_response_list.findIndex(obj =>
      obj.activity_response_id === engagement.activity_response_id &&
      obj.id === engagement.response_id
    );
    let relativeEngagements = response.engagementSummary[engagement.type];
    relativeEngagements = relativeEngagements.filter(function(engagement) {
      return engagement.id != engagement.id;
    });

    state.activity_response_list[index].engagementSummary[engagement.type] = relativeEngagements;
  }

}

export default EngagementHelpers;