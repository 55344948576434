import VuexHelpers from "../../helpers/VuexHelpers";

// action types
export const CREATE_PROJECT_FORUM_RESPONSE_SENTIMENT         = "createProjectForumResponseSentiment";
export const DELETE_PROJECT_FORUM_RESPONSE_SENTIMENT         = "deleteProjectForumResponseSentiment";

// mutation types
export const SET_PROJECT_FORUM_RESPONSE_SENTIMENT_LIST       = "setProjectForumResponseSentimentList";
export const SET_PROJECT_FORUM_RESPONSE_SENTIMENT_INFO       = "setProjectForumResponseSentimentInfo";
export const SET_PROJECT_FORUM_RESPONSE_SENTIMENT_ERRORS     = "setProjectForumResponseSentimentErrors";
export const ADD_PROJECT_FORUM_RESPONSE_SENTIMENT            = "addProjectForumResponseSentiment";
export const STRIP_PROJECT_FORUM_RESPONSE_SENTIMENT          = "stripProjectForumResponseSentiment";

const state = {
  project_forum_response_sentiment_list: [],
  project_forum_response_sentiment_info: null,
  project_forum_response_sentiment_errors: null,
};

const getters = {
  projectForumResponseSentimentList(state) {
    return state.project_forum_response_sentiment_list;
  },
  projectForumResponseSentimentInfo(state) {
    return state.project_forum_response_sentiment_info;
  },
  projectForumResponseSentimentErrors(state) {
    return state.project_forum_response_sentiment_errors;
  }
};

const actions = {
  [CREATE_PROJECT_FORUM_RESPONSE_SENTIMENT](context, payload) {
    let url = `api/v1/projects/${payload.projectId}/forums/${payload.projectForumId}/forum_response_sentiments`
    return VuexHelpers.create("ProjectForumResponseSentiment", context, url, payload);
  },
  [DELETE_PROJECT_FORUM_RESPONSE_SENTIMENT](context, payload) {
    let url = `api/v1/projects/${payload.projectId}/forums/${payload.projectForumId}/forum_response_sentiments/${payload.id}`;
    return VuexHelpers.delete("ProjectForumResponseSentiment", context, url, payload.id);
  },
}

const mutations = {
  [SET_PROJECT_FORUM_RESPONSE_SENTIMENT_LIST](state, project_forum_response_sentiment_list) {
    state.project_forum_response_sentiment_list = project_forum_response_sentiment_list;
  },
  [SET_PROJECT_FORUM_RESPONSE_SENTIMENT_INFO](state, project_forum_response_sentiment_info) {
    state.project_forum_response_sentiment_info = project_forum_response_sentiment_info;
  },
  [SET_PROJECT_FORUM_RESPONSE_SENTIMENT_ERRORS](state, errors = null) {
    state.project_forum_response_sentiment_errors = errors;
  },
  [ADD_PROJECT_FORUM_RESPONSE_SENTIMENT](state, project_forum_response_sentiment_info) {
    state.project_forum_response_sentiment_list.push(project_forum_response_sentiment_info);
  },
  [STRIP_PROJECT_FORUM_RESPONSE_SENTIMENT](state, id) {
    VuexHelpers.strip(state, 'project_forum_response_sentiment', id);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};