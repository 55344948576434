import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_USER_NOTIFICATIONS_LIST = "populateUserNotifications";
export const CLEAR_USER_NOTIFICATIONS_LIST = "clearUserNotifications"
export const UPDATE_USER_NOTIFICATION = "updateUserNotifications";
export const DELETE_USER_NOTIFICATION = "deleteUserNotifications";
export const UPDATE_ALL_USER_NOTIFICATIONS = "updateAllUserNotifications";
export const DELETE_ALL_USER_NOTIFICATIONS = "deleteAllUserNotifications";
export const CLEAR_USER_NOTIFICATION_ERRORS = "clearUserNotificationErrors";

// mutation types
export const SET_USER_NOTIFICATIONS_LIST = "setUserNotificationsList";
export const RESET_USER_NOTIFICATIONS_LIST = "resetUserNotificationsList";
export const SET_USER_NOTIFICATION_ERRORS = "setUserNotificationErrors";
export const RESET_USER_NOTIFICATION_ERRORS = "resetUserNotificationErrors";
export const REPLACE_USER_NOTIFICATION = "replaceUserNotification";
export const REMOVE_USER_NOTIFICATION = "removeUserNotification";

const state = {
  errors: {},
  notifications_list: []
};

const getters = {
  notificationErrors(state) {
    return state.errors;
  },
  notificationList(state) {
    return state.notifications_list;
  }
};

const actions = {
  [POPULATE_USER_NOTIFICATIONS_LIST](context, payload) {
    return ApiService.get("api/v1/users/" + payload.userId + "/notifications", { params: payload.params })
      .then((res) => {
        const notifications = res.data;
        context.commit(SET_USER_NOTIFICATIONS_LIST, notifications);
        return notifications;
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_USER_NOTIFICATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      }); 
  },
  [CLEAR_USER_NOTIFICATIONS_LIST](context) {
    context.commit(RESET_USER_NOTIFICATIONS_LIST);
  },
  [UPDATE_USER_NOTIFICATION](context, payload) {
    return ApiService.put("api/v1/users/" + payload.userId + "/notifications/" + payload.id)
      .then(res => {
        const notification = res.data;
        let payload2 = { id: payload.id, notification: notification };
        context.commit(REPLACE_USER_NOTIFICATION, payload2);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_USER_NOTIFICATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      }); 
  },
  [DELETE_USER_NOTIFICATION](context, payload) {
    return ApiService.delete("api/v1/users/" + payload.userId + "/notifications/" + payload.id)
      .then(() => {
        context.commit(REMOVE_USER_NOTIFICATION, payload.id);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_USER_NOTIFICATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      }); 
  },
  [UPDATE_ALL_USER_NOTIFICATIONS](context, payload) {
    return ApiService.put("api/v1/users/" + payload.userId + "/all_notifications/")
      .then(res => {
        const notifications = res.data;
        context.commit(SET_USER_NOTIFICATIONS_LIST, notifications);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_USER_NOTIFICATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      }); 
  },
  [DELETE_ALL_USER_NOTIFICATIONS](context, payload) {
    return ApiService.delete("api/v1/users/" + payload.userId + "/all_notifications/")
      .then(() => {
        context.commit(RESET_USER_NOTIFICATIONS_LIST);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_USER_NOTIFICATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      }); 
  },
  [CLEAR_USER_NOTIFICATION_ERRORS](context) {
    context.commit(RESET_USER_NOTIFICATION_ERRORS);
  }
};

const mutations = {
  [SET_USER_NOTIFICATIONS_LIST](state, notifications) {
    state.notifications_list = notifications;
  },
  [RESET_USER_NOTIFICATIONS_LIST](state) {
    state.notifications_list = [];
  },
  [SET_USER_NOTIFICATION_ERRORS](state, errors)
  {
    state.errors = errors;
  },
  [RESET_USER_NOTIFICATION_ERRORS](state)
  {
    state.errors = {};
  },
  [REPLACE_USER_NOTIFICATION](state, payload)
  {
    state.notifications_list = state.notifications_list.filter(function( obj ) {
      return obj.id !== payload.id;
    });
    state.notifications_list.push(payload.notification);
  },
  [REMOVE_USER_NOTIFICATION](state, notificationId)
  {
    state.notifications_list = state.notifications_list.filter(function( obj ) {
      return obj.id !== notificationId;
    }); 
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};