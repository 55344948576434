<template>
  <div>
    <a
      id="notepad_toggle"
      @click="$emit('toggleNotepad', {
        tab: targetTab,
        morphType: morphType,
        morphId: morphId,
        activityId: activityId
      })"
      href="javascript:void(0)"
      class="btn btn-icon-primary btn-sm btn-text-dark-50 bg-hover-light-primary btn-hover-text-primary rounded font-weight-bolder font-size-xs p-2"
      v-b-tooltip.hover.bottom
      :title="tooltip"
    >
      <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
        <i :class="iconClass"></i>
      </span>
      {{ (type == "notes") ? totalNotes : totalTags }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EngagementNotepad",

  props: {
    activityId: { type: Number, required: true },
    morphType: { type: String, required: true },
    morphId: { type: Number, required: true },
    response: { type: Object, required: true },
    type: { type: String, required: false, default: "notes" },
    legacy: { type: Boolean, required: false, deafult: false}
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),

    myNotes: function() {
      let self = this;
      return this.response.notes.filter(function( note ) {
        return note.user_id == self.currentUser.id;
      });
    },

    myTags: function() {
      let self = this;
      return this.response.tags.filter(function( tag ) {
        return tag.user_id == self.currentUser.id;
      });
    },
    
    totalNotes: function() {
      return this.response.notes.length;
    },

    totalTags: function() {
      return this.response.tags.length;  
    },
  
    tooltip: function() {
      let tooltip = null;

      if (this.type == "notes") {
        if (this.myNotes.length != 0) {
          return `${this.$t("REVIEW.STREAM.NOTE_SUMMARY_ME")}`;
        } else if (this.totalNotes == 1) {
          return this.totalNotes + `${this.$t("REVIEW.STREAM.NOTE_SUMMARY_SINGULAR")}`;
        } else if (this.totalNotes > 1 || this.totalNotes == 0) {
          return this.totalNotes + `${this.$t("REVIEW.STREAM.NOTE_SUMMARY_PLURAL")}`;
        };
      } else {
        if (this.myTags.length != 0) {
          return `${this.$t("REVIEW.STREAM.TAG_SUMMARY_ME")}`;
        } else if (this.totalTags == 1) {
          return this.totalTags + `${this.$t("REVIEW.STREAM.TAG_SUMMARY_SINGULAR")}`;
        } else if (this.totalTags > 1 || this.totalTags == 0) {
          return this.totalTags + `${this.$t("REVIEW.STREAM.TAG_SUMMARY_PLURAL")}`;
        };
      }

      return tooltip;
    },

    iconClass: function() {
      let baseClass = "";
      if (this.type == "notes") {
        baseClass = "fa-sticky-note text-primary fa-lg";
        if (this.myNotes.length > 0) {
          baseClass = baseClass + " fas";
        } else {
          baseClass = baseClass + " far";
        }
      } else {
        baseClass = "fa-tag fa-lg fas";
        if (this.myTags.length > 0) {
          baseClass = baseClass + " text-brand";
        } else {
          baseClass = baseClass + " text-light";
        }
      }

      return baseClass;
    },

    targetTab: function() {
      return (this.type == "notes") ? 1 : 2;
    }
  }
};
</script>
