import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          redirect: "/activities",
          component: () => import("@/view/pages/Main.vue"),
          meta: {
            requiresAuth: true,
            scope: "core",
            widgets: "global",
            title: "Home | Input | Together"
          },
          children: [
            {
              path: "/activities",
              name: "activities",
              redirect: "/activities/view",
              component: () => import("@/view/layout/Activity.vue"),
              children: [
                {
                  path: "view",
                  name: "view_activities",
                  component: () => import("@/view/pages/activity/ViewList.vue"),
                  meta: {
                    requiresAuth: true,
                    scope: "activity",
                    widgets: "global",
                    title: "Home | Input | Together"
                  }
                },
                {
                  path: "room_view/:id",
                  name: "view_room_activities",
                  component: () => import("@/view/pages/activity/RoomViewList.vue"),
                  meta: {
                    requiresAuth: true,
                    scope: "activity",
                    widgets: "global",
                    title: "Room | Input | Together"
                  }
                }
              ]
            },
            {
              path: "/activity/:id",
              name: "activity",
              redirect: "/activity/:id/view",
              component: () => import("@/view/layout/Activity.vue"),
              children: [
                {
                  path: "view/:responseId?",
                  name: "view_activity",
                  component: () => import("@/view/pages/activity/View.vue"),
                  meta: {
                    requiresAuth: true,
                    scope: "activity",
                    widgets: "activity_limited"
                  }
                }
              ]
            },
            {
              path: "/page/:id",
              name: "page",
              redirect: "/page/:id/view",
              component: () => import("@/view/layout/Page.vue"),
              children: [
                {
                  path: "view",
                  name: "view_page",
                  component: () => import("@/view/pages/page/View.vue"),
                  meta: {
                    requiresAuth: true,
                    scope: "page",
                    widgets: "cm_only"
                  }
                },
                {
                  path: "section/:pageSectionId",
                  name: "view_page_section",
                  component: () => import("@/view/pages/page-section/View.vue"),
                  meta: {
                    requiresAuth: true,
                    scope: "page",
                    widgets: "cm_only"
                  }
                }
              ]
            },
            {
              path: "/community/:id",
              name: "community",
              redirect: "/community/:id/view",
              component: () => import("@/view/layout/Community.vue"),
              children: [
                {
                  path: "view",
                  name: "view_member",
                  component: () => import("@/view/pages/community/View.vue"),
                  meta: {
                    requiresAuth: true,
                    scope: "community",
                    widgets: "cm_only"
                  }
                }
              ]
            },
            {
              path: "/profile",
              name: "profile",
              component: () => import("@/view/pages/profile/EditProfile.vue"),
              meta: {
                requiresAuth: true,
                scope: "ouser",
                widgets: "none",
                title: "Profile | Input | Together"
              }
            },
            {
              path: "/forum",
              name: "view_forum",
              component: () => import ("@/view/pages/forum/View.vue"),
              meta: {
                requiresAuth: true,
                scope: "forum",
                widgets: "none",
                title: "Forum | Input | Together"
              }
            },
            {
              path: "/forum/:id",
              name: "view_forum_topic",
              component: () => import ("@/view/pages/forum/Topic.vue"),
              meta: {
                requiresAuth: true,
                scope: "forum",
                widgets: "none",
                title: "Forum | Input | Together"
              }
            }
          ]
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/modules/together-authentication/layout/Auth2.vue"),
      children: [
        {
          path: "/login/:key?/:ps?/:pid?/:guid?/:variables?",
          name: "login",
          component: () => import("@/modules/together-authentication/pages/auth2/Login.vue"),
          meta: { requiresAuth: false }
        },
        {
          path: "/register/:token",
          name: "register",
          component: () => import("@/modules/together-authentication/pages/auth2/Register.vue"),
          meta: { requiresAuth: false }
        },
        {
          path: "/request_reset/:origin?",
          name: "request_reset",
          component: () => import("@/modules/together-authentication/pages/auth2/Request.vue"),
          meta: { requiresAuth: false }
        },
        {
          path: "/reset_password/:token",
          name: "reset_password",
          component: () => import("@/modules/together-authentication/pages/auth2/Change.vue"),
          meta: { requiresAuth: false }
        },
        {
          path: "/terms_and_conditions",
          name: "terms_and_conditions",
          component: () => import("@/modules/together-authentication/pages/auth2/Terms.vue"),
          meta: { requiresAuth: false, scope: "auth" }
        },
        {
          path: "/privacy",
          name: "privacy",
          component: () => import("@/modules/together-authentication/pages/auth2/Privacy.vue"),
          meta: { requiresAuth: false, scope: "auth" }
        },
        {
          path: "/help",
          name: "help",
          component: () => import("@/modules/together-authentication/pages/auth2/Help.vue"),
          meta: { requiresAuth: false, scope: "auth" }
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
