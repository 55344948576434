import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const GENERIC_RESOURCE = "genericResource";

const state = {};

const getters = {};

const actions = {
  /* eslint-disable */
  [GENERIC_RESOURCE](context, payload) {

    let route = payload.route;

    let data = (payload.data != undefined) ? payload.data : {};
    let params = (payload.params != undefined) ? payload.params : {};
    let filters = (payload.filters != undefined) ? payload.filters : {};
    let ordering = (payload.ordering != undefined) ? payload.ordering : {};
    let relations = (payload.relations != undefined) ? payload.relations : {};
    let attributes = (payload.attributes != undefined) ? payload.attributes : {};
    let localisation = (payload.localisation != undefined) ? payload.localisation : 0;
    let finalParams = params;
    
    //Apply data arguments
    finalParams.x_data = {};
    for (const [key, value] of Object.entries(data)) {
      finalParams.x_data[key] = value;
    }
    
    //Apply filter arguments
    finalParams.x_filter = {};
    for (const [key, value] of Object.entries(filters)) {
      finalParams.x_filter[key] = value;
    }

    //Apply ordering arguments
    finalParams.x_order = {};
    for (const [key, value] of Object.entries(ordering)) {
      finalParams.x_order[key] = value;
    }

    //Apply relationship arguments
    finalParams.x_relations = {};
    for (const [key, value] of Object.entries(relations)) {
      finalParams.x_relations[key] = value;
    }

    //Apply attributes arguments
    finalParams.x_attributes = attributes;

    //Apply localisation arguments
    finalParams.x_localisation = localisation;

    //Apply domain arguments
    finalParams.domain = window.location.host.split('.')[0];

    return ApiService.get(route, { params: finalParams })
      .then(res => {
        if (res.data.meta != undefined || res.data.data == undefined) {
          return res.data;
        } else {
          return res.data.data;          
        }
      })
      .catch(error => {
        if (error.response) {
          return error.response.data
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  /* eslint-enable */
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};