<template>
  <span> {{ displayEmail }} </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GenericUserEmail",

  props: {
    user: { type: Object, required: true }
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    displayEmail: function() {
      let canSeeEmail = false;
      
      let relationToProject = this.currentUser.relation_to_project;

      if (this.currentUser.id == this.user.id) {
        canSeeEmail = true;
      } else if (relationToProject == "super_admin" || relationToProject == "admin") {
        canSeeEmail = true;
      } else if (relationToProject == null) {
        canSeeEmail = true;
      }

      return (canSeeEmail) ? this.user.calculatedEmail : "Email Hidden";
    }
  }
}
</script>