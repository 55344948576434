import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_SURVEY_QUESTION_CHOICE_LIST = "populateSurveyQuestionChoiceList";
export const CLEAR_SURVEY_QUESTION_CHOICE_LIST = "clearSurveyQuesitonChoiceList";
export const CLEAR_SURVEY_QUESTION_CHOICE_ERRORS = "clearSurveyQuestionChoiceErrors";

// mutation types
export const SET_SURVEY_QUESTION_CHOICE_LIST = "setSurveyQuestionChoiceList";
export const RESET_SURVEY_QUESTION_CHOICE_LIST = "resetSurveyQuestionChoiceList";
export const SET_SURVEY_QUESTION_CHOICE_ERRORS = "setSurveyQuestionChoiceErrors";
export const RESET_SURVEY_QUESTION_CHOICE_ERRORS = "resetSurveyQuestionChoiceErrors";

const state = {
  survey_question__choice_errors: {},
  survey_question_choice_list: [],
  survey_question_choice_info: []
};

const getters = {
  surveyQuestionChoiceErrors(state) {
    return state.survey_question_choice_errors;
  },
  surveyQuestionChoiceList(state) {
    return state.survey_question_choice_list;
  },
  surveyQuestionChoiceInfo(state) {
    return state.survey_question_choice_info;
  },
};

const actions = {
  [POPULATE_SURVEY_QUESTION_CHOICE_LIST](context, payload) {
    return ApiService.get("api/v1/activity_survey_questions/" + payload.surveyQuestionId + "/survey_question_choices", {
      params: payload.params
    }).then((res) => {
      const surveyQuestionChoices = res.data.data._embedded.survey_question_choices;
      context.commit(SET_SURVEY_QUESTION_CHOICE_LIST, surveyQuestionChoices);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_SURVEY_QUESTION_CHOICE_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error);
      }
      throw error;
    });
  },
  [CLEAR_SURVEY_QUESTION_CHOICE_ERRORS](context) {
    context.commit(RESET_SURVEY_QUESTION_CHOICE_ERRORS);
  }
}

const mutations = {
  [SET_SURVEY_QUESTION_CHOICE_LIST](state, surveyQuestionChoices) {
    state.survey_question_choice_list = surveyQuestionChoices;
  },
  [RESET_SURVEY_QUESTION_CHOICE_LIST](state) {
    state.survey_question_choice_list = [];
  },
  [SET_SURVEY_QUESTION_CHOICE_ERRORS](state, errors) {
    state.survey_question_choice_errors = errors;
  },
  [RESET_SURVEY_QUESTION_CHOICE_ERRORS](state) {
    state.survey_question_choice_errors = {};
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};