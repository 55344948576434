import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_PROFILE = "populateProfileResponses";
// mutation types
export const SET_PROFILE_RESPONSES = "setProfileResponses";
export const SET_PROFILE_RESPONSES_ERRORS = "setProfileResponsesError";

const state = {
  profile_responses: [],
  errors: {}
};

const getters = {
  profileActivityResponses(state) {
    return state.profile_responses;
  },
  profileActivityResponsesErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROFILE](context, payload) {
    return ApiService.get(
      "api/v1/project_profiles/" +
        payload.projectProfileId +
        "/activity_responses",
      {
        params: payload.params
      }
    )
      .then(res => {
        const profileActivityResponses =
          res.data.data._embedded.activity_responses;
        context.commit(SET_PROFILE_RESPONSES, profileActivityResponses);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROFILE_RESPONSES_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  }
};

const mutations = {
  [SET_PROFILE_RESPONSES](state, profileActivityResponses) {
    state.profile_responses = profileActivityResponses;
  },
  [SET_PROFILE_RESPONSES_ERRORS](state, errors) {
    state.errors = errors;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
